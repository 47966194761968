import { SStorage } from "@/utils/cache-utils.js";
import { E_BID_PROJECT_ID, PROJECT_INFO } from "../const/gloabl-const.js";
// 存放进入项目后的 项目相关主要信息
const loginStore = {
    namespaced: true,
    state() {
        return {

            projectInfo: {}, // 项目信息
            procurementMethod:'',// 采购方式
            currentSection:{},// 当前标段信息
           
        };
    },
    mutations: {
    
        setCurrentSection(state,obj){
            state.currentSection=obj
            SStorage.set('currentSection',obj)
        },
        setProcurementMethod(state,value){
            state.procurementMethod=value
            SStorage.set('procurementMethod',value)
        }
    },
    actions: {
        // 保证vuex 刷新是有数据的
        loadStore({ commit, dispatch, state }) {
            // const projectInfo = SStorage.get(PROJECT_INFO);
            const procurementMethod=SStorage.get('procurementMethod')
            const currentSection=SStorage.get('currentSection')
            // if (projectInfo) {
            //     commit("setEBidProjectInfoStore", { ...projectInfo });
            // }
            if(currentSection){
                commit("setCurrentSection", currentSection);
            }
            if(procurementMethod){
                commit("setProcurementMethod", procurementMethod);
            }
        }
    }
};
export default loginStore;
