/**
 * 工具类
 */

import Vue from "vue";
import { Message } from "element-ui";
import {
    saveAs
} from "file-saver";
import api from "@/api/api";
// 指定使用的语言

//打印样式
const printStyle =
    '<style media="print">div,p,table,td,th{border-color: #000000;}html,body{margin: 0;padding: 15px 20px 0;}table,table tr th, table tr td { border:1px solid #000000; padding: 10px;}table {border-collapse: collapse;width: 100%;margin: 0 auto;}.cell{padding: 10px;}p{text-indent:0;}</style>';

/*
工具类用于放置各种特定函数
 */
const Util = {};

//获取URL指定参数
Util.getUrlParam = function (name, str) {
    let reg = new RegExp("(\\?|&)" + name + "=([^&]*)(&|$)", "i"); // eslint-disable-line
    let r = "";
    if (str) {
        r = str.match(reg);
    } else {
        r = window.location.href.match(reg);
    }
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

//根据loginType判断是否显示新增按钮
//  loginType=4  监督
Util.isShowNewBtn = function () {
    if (sessionStorage.getItem("loginType") === 4) {
        return false;
    }
    return true;
};
//验证手机号
Util.checkPhone = function (rule, value, callback) {
    setTimeout(function () {
        if (Util.checkFormat('phone', value)) {
            callback()
        } else {
            callback(new Error("电话号码格式:18988888888"))
        }
    }, 100)
};
//阿拉伯数字转换为大写
Util.amountLtoU=async function (amount) {
    if (isNaN(amount) || amount >= 1000000000000) {
        return "无效金额！"; //数值最大不超过1万亿
    }
    let sPrefix = amount < 0 ? "(负)" : ""; //将负号‘-’显示成汉字‘(负)’
    let sAmount = Math.abs(amount).toFixed(2); //格式化成两位小数格式（两位小数对应“'角”和“分”）
    let sUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'; //14个权位单位
    let sCapital = '零壹贰叁肆伍陆柒捌玖'; //10个大写数字
    sAmount = sAmount.replace(".", ""); //去除小数点（使数字与权位对应）
    sUnit = sUnit.substr(sUnit.length - sAmount.length); //截取权位
    let sOutput = "";
    for (var i = 0, len = sAmount.length; i < len; i++) { //拼接数字与权位单位
        sOutput += sCapital.substr(sAmount.substr(i, 1), 1) + sUnit.substr(i, 1);
    }
    return sPrefix + sOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零')
        .replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元");
};

//验证密码
Util.checkPassword = function (rule, value, callback) {
    setTimeout(function () {
        if (Util.checkFormat('password', value)) {
            callback()
        } else {
            callback(new Error(" "))
        }
    }, 100)
};
//验证各种格式
Util.checkFormat = function (type, vaule) {
    var result = false;
    switch (type) {
        case "password": //密码，最短9位，最长23位 {9,23}，必须包含1个数字，必须包含1个小写字母，必须包含1个大写字母，必须包含1个特殊字符
            if (/^.*(?=.{9,23})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\(\)]).*$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "phoneValidCode": //手机验证码
            if (/^\d{1,6}$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "phone": //手机号码
            if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "landline": //座机号码，固定电话
            if (/^0\d{2,3}-?\d{7,8}$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "phoneOrLandline": //手机号码或座机号码
            if (/^(1(3|4|5|6|7|8|9)\d{9})$/.test(vaule) || /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(vaule)) {
                result = true
            }
            break;
        case "email": //电子邮箱
            if (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "idcard": //身份证号码（18位）
            if (/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        case "number": //大于0的正数
            if (/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(vaule)) {
                // eslint-disable-line
                result = true;
            }
            break;
        default:
            break;
    }
    return result;
};


// Util.get_a_header_download = function(url, name) {
//     if (name && url.indexOf('/api/fileInfo/downloadFile') > -1) {
//         Vue.prototype.$showLoading('文件下载中...');
//         var a = document.createElement('a');
//         a.download = name;
//         //此处填写文件地址
//         a.href = url;
//         document.getElementsByTagName("body")[0].appendChild(a); // 修复firefox中无法触发click
//         a.click();
//         Vue.prototype.$closeLoading();
//     } else {
         
//             api.common.downloadFile(url).then(res => {
//             let fileName = name;
//             // 有文件名就用自定义的，没有就从header获取
//             if (!fileName) {
//                 fileName = fileNameFromHeader(
//                     res.headers["content-disposition"] || ""
//                 );
//             }
//             let blob = new Blob([res.data], {
//                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
//             });
//             saveAs(blob, fileName);
//         }).catch(res => {
//             Message({
//                 message: res.message,
//                 type: 'error'
//             });
//         });
//     }}
/*
 * 模拟a标签文件下载
 * @params {string} url 文件路径
 * @params {string} name 输出的文件名称
 * */
Util.get_a_header_download = function (url, name) {
    let access_token = sessionStorage.getItem("access_token");
    //模拟a下载
    let xhr = new XMLHttpRequest(); //定义http请求对象
    xhr.open("get", url, true); //根据接口使用请求方式
    xhr.responseType = "blob"; // 返回类型blob
    xhr.setRequestHeader("Authorization", "Bearer " + access_token);
    // xhr.setRequestHeader("Content-type", "application/octet-stream");
    Vue.prototype.$showLoading("文件下载中...");
    //定义请求完成的处理函数,请求前可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function () {
        if (this.status === 200) {
            //请求完成
            var blob = this.response;
            if (blob.type === "application/json") {
                let reader = new FileReader();
                reader.readAsText(blob);
                setTimeout(() => {
                    const result = JSON.parse(reader.result);
                    Message({
                        message: result.msg,
                        type: "error"
                    });
                    Vue.prototype.$closeLoading();
                }, 500);
                return false;
            }
            if (!name) {
                //没有名称获取接口返回的名称
                let arr = xhr
                    .getAllResponseHeaders()
                    .toLowerCase()
                    .trim()
                    .split(/[\r\n]+/);
                var headers = {};
                arr.forEach(function (line) {
                    var parts = line.split(": ");
                    var header = parts.shift();
                    var value = parts.join(": ");
                    headers[header] = value;
                });
                console.log(headers)
                name = decodeURI(headers["content-disposition"].split(";")[1].split("filename=")[1].replace(new RegExp('"', "g"), ""));
            }
            if ("msSaveOrOpenBlob" in navigator) {
                //兼容ie浏览器下载报传递给系统调用的数据区域太小.
                var data = this.response;
                blob = new Blob([data]);
                window.navigator.msSaveOrOpenBlob(blob, name);
                Vue.prototype.$closeLoading();
                return;
            } else {
                var reader = new FileReader();
                reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                reader.onload = function (e) {
                    //查看有没有接收到数据流
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement("a");
                    a.download = name;
                    //此处填写文件地址
                    a.href = e.target.result;
                    document.getElementsByTagName("body")[0].append(a); // 修复firefox中无法触发click
                    a.click();
                    Vue.prototype.$closeLoading();
                    //document.getElementsByTagName(a)[0].remove();
                };
            }
        } else {
            Vue.prototype.$closeLoading();
        }
    };
    xhr.send();
};

/*
    @打印方法
    @params {string} html: 需要传入的html
    @params {string} direction: 方向  值 portrait（纵向） landscape（横向）
*/
Util.printing = function (html, direction = "portrait") {
    let str = "";
    if (direction === "portrait") {
        str = '<style type="text/css" media="print">\n' + "  @page { size: portrait; }\n" + "</style>";
    } else {
        str = '<style type="text/css" media="print">\n' + "  @page { size: landscape; }\n" + "</style>";
    }
    let u = window.open("打印", "_blank");
    let h =
        '<!DOCTYPE html><html><head><meta charset="utf-8">' +
        printStyle +
        '<style media="print">@page{size: auto;margin: 3mm;}</style></head><body>';
    let v = "</body></html>";
    let ie_h =
        '<!DOCTYPE html><html><head><meta charset="utf-8">' +
        printStyle +
        '<style media="print">@page{size: auto;margin: 3mm;}</style><script>';
    ie_h +=
        'let hkey_path;hkey_path = "HKEY_CURRENT_USER\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";try {var RegWsh = new ActiveXObject("WScript.Shell");RegWsh.RegWrite(hkey_path + "header", "");RegWsh.RegWrite(hkey_path + "footer", "");} catch (e) {}';
    ie_h += "<" + "/" + "script></head><body>";

    if (!!window.ActiveXObject || "ActiveXObject" in window) {
        //是否ie
        u.document.write(ie_h + str + html + v);
    } else {
        u.document.write(h + str + html + v);
    }
    u.document.close();
    u.print();
    u.close();
};

/**
 * 纯下载附件
 * @param id
 */
Util.downloadFile = (id, hostType = "ApiUrl") => {
    Util.getFlieDownloadUrl(
        {
            fileId: id
        },
        hostType
    )
        .then(res => {
            if (res.code === 0) {
                //如果为IE
                if (!!window.ActiveXObject || "ActiveXObject" in window) {
                    window.open(res.data.url);
                } else {
                    window.open(res.data.url);
                }
            } else {
                Message({
                    message: res.msg,
                    type: "error"
                });
            }
        })
        .catch(res => {
            Message({
                message: res.message,
                type: "error"
            });
        });
};

/**
 * 金额输入校验 只能保留两位小数(input的type不能为number)
 * 使用 @blur="绑定的值 = $util.checkPrice($event,1)" @keyup.native="$util.checkPrice($event)"
 *
 * @param id
 */
Util.checkPrice = (e, flag) => {
    console.log(e)
    //flag==1  去掉数字最后的.
    e.target.value = e.target.value
        .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
        .replace(/^\./g, "") // 保证第一个为数字而不是.
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
    if (e.target.value.indexOf(".") < 0 && e.target.value !== "") {
        // 如果没有小数点，首位不能为类似于 01、02的金额
        e.target.value = parseFloat(e.target.value) + "";
    } else if (e.target.value.indexOf(".") >= 0) {
        //只保留两位小数
        if (flag === 1) {
            if (e.target.value.split(".").length > 1 && e.target.value.split(".")[1].length > 0) {
                e.target.value = e.target.value.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3");
            } else {
                //失去焦点时若最后一位是. 则删除
                e.target.value = e.target.value.replace(/\./g, "");
            }
        } else {
            e.target.value = e.target.value.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3");
        }
    }
    if (flag === 1) {
        e.target.value = e.target.value - 0;
    }
    return e.target.value;
};

/**
 *
 * 去除所有空格
 */
Util.removeAllSpace = str => {
    return str.replace(/\s+/g, "");
};

/**
 *
 * 复制
 */
Util.copyStr = str => {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", str);
    input.select();
    document.execCommand("copy"); // 执行浏览器复制命令
    if (document.execCommand("copy")) {
        document.execCommand("copy");
        Message({
            message: "复制成功！",
            type: "success"
        });
    }
    document.body.removeChild(input);
};

Util.openUrl = url => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    document.getElementsByTagName("body")[0].append(a);
    a.click();
    document.getElementsByTagName(a)[0].remove();
};
Util.scrollBottom = (dom, topx) => {
    document.getElementById(dom).scrollTop = document.getElementById(dom).scrollHeight;
};

Util.unifyNumber=(num)=>{
    if (num === '') {
      return 0
    } else {
    //   let handleNum = parseFloat(num)
      let handleNum = Number(num)
      let isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > 2
    //   if (isToFixed) {
    //     return handleNum.toFixed(2)
    //   } else {
    //     return handleNum
    //   }
    return handleNum.toFixed(2)
    }
  }

// 超大金额数字转大写
Util.digitUppercase = (money) => {
    // 转字符串
    money = money + ''
    if (money != null && money != '' && money != 'null') {
        money = money.replace(',', '')
        //汉字的数字
        const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
        //基本单位
        const cnIntRadice = ["", "拾", "佰", "仟"];
        //对应整数部分扩展单位
        const cnIntUnits = ["", "万", "亿", "兆"];
        //对应小数部分单位
        const cnDecUnits = ["角", "分", "毫", "厘"];
        //整数以后的单位
        const cnIntLast = "元";
        //无小数时后缀字符
        const cnInteger = "整";
        //最大处理的数字
        const maxNum = 99999999999999.99;
        //金额整数部分
        let integerNum = "";
        //金额小数部分
        let decimalNum = "";
        //最后输出的中文金额字符串
        let chineseMoney = "";
        // 传入的参数为空情况
        if (!money) {
            return "";
        }
        const numberMoney = parseFloat(money);
        if (numberMoney >= maxNum) {
            return "超出最大范围";
        }

        // 传入的参数为0情况
        if (numberMoney == 0) {
            chineseMoney = cnNums[0] + cnIntLast + cnInteger;
            return chineseMoney;
        }
        if (money.indexOf(".") == -1) {
            //没有小数点
            integerNum = money;

        } else {
            //有小数点
            let parts = money.split(".");
            if (parts.length > 2) {
                return "格式错误";
            } else if (parts[1].length > 6) {
                return "小数点后最多六位";
            }
            integerNum = parts[0];
            decimalNum = parts[1];
        }
        //转换整数部分
        if (parseInt(integerNum, 10) > 0) {
            let zeroCount = 0; //连续出现的零的个数
            let IntLen = integerNum.length;
            let integerArr = integerNum.split("");
            integerArr.forEach((item, index) => {
                let residue = IntLen - index - 1; //当前剩余需要转换的位数
                let m = residue % 4; //m判断当前位数在四位轮回的第几位
                if (item == "0") {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseMoney += cnNums[0];
                    }
                    zeroCount = 0;
                    chineseMoney += cnNums[parseInt(item)] + cnIntRadice[m];
                }
                if (m == 0 && zeroCount < 4) {
                    //m为0时表示在4的倍数位，需多加上大单位
                    let bigUnit = residue / 4;
                    chineseMoney += cnIntUnits[bigUnit];
                }
            });
            chineseMoney += cnIntLast; // 最后加上元
        }
        // 转换小数部分
        if (decimalNum != "" && Number(decimalNum) > 0) {
            let decimalArr = decimalNum.split("");
            decimalArr.forEach((item, index) => {
                if (item != "0") {
                    chineseMoney += cnNums[Number(item)] + cnDecUnits[index];
                } else {
                    chineseMoney += cnNums[Number(item)];
                }

            });
        } else {
            chineseMoney += cnInteger;
        }
        return chineseMoney;
    }
    return ''
};

//获取当前日期昨天、今天、明天的日期
Util.getDay=(day,startDay)=>{
    var today = startDay ? new Date(startDay) : new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds);//关键代码
    var tYear = today.getFullYear();
    var tMonth = today.getMonth();
    var tDate = today.getDate();
    tMonth = Util.doHandleMonth(tMonth+1);
    tDate =Util.doHandleMonth(tDate);
    return tYear+'-'+tMonth+'-'+tDate
};
Util.doHandleMonth = (month)=>{
    var m = month;
    if(month.toString().length == 1){
        m="0"+month;
    }
    return m
}

/**
 * 金额输入校验 只能保留两位小数(input的type不能为number)
 * 使用 @blur="绑定的值 = $util.checkNum(num,1)" @keyup.native="$util.checkNum(num)"
 * numLength: 保留的小数位数 2或6 默认为2
 * max： 最大值
 * 
 * @param id
 */
Util.checkNum = (num, flag, numLength = 2, max = null) => { //flag==1  去掉数字最后的.
    num = num.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace(/^\./g, '') // 保证第一个为数字而不是.
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
    if (num.indexOf('.') < 0 && num !== '') {
        // 如果没有小数点，首位不能为类似于 01、02的金额
        num = parseFloat(num) + ''
    } else if (num.indexOf('.') >= 0) {
        //只保留两位小数
        if (flag == 1) {
            if (num.split('.').length > 1 && num.split('.')[1].length > 0) {
                if (numLength == 2) {
                    num = num.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
                } else if (numLength == 6) {
                    num = num.replace(/^()*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3');
                } else if (numLength == -1) {
                    num = num.split('.')[0];
                }
            } else {
                //失去焦点时若最后一位是. 则删除
                num = num.replace(/\./g, '');
            }
        } else {
            if (numLength == 2) {
                num = num.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3');
            } else if (numLength == 6) {
                num = num.replace(/^()*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3');
            } else if (numLength == -1) {
                num = num.split('.')[0];
            }
        }
    }
    if (flag == 1) {
        num = num - 0;
    }
    if (max && num > max) {
        num = max;
    }
    return num;
};
export default Util;
