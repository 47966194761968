import axios from "../axios";

/***
 * 保存
 * @param params
 */
export const saveSubscribe = data => {
    return axios({
        url: `/api/subscription_service/add`,
        method: "post",
        data
    });
};
export const editSubscribe = data => {
    return axios({
        url: `/api/subscription_service/edit`,
        method: "post",
        data
    });
};

export const historyList = params => {
    return axios({
        url: `/api/subscription_service/history/list`,
        method: "get",
        params
    });
};

export const categoryList = params => {
    return axios({
        url: `/api/subscription_service/category/list`,
        method: "get",
        params
    });
};

export const companyList = params => {
    return axios({
        url: `/api/subscription_service/company/list`,
        method: "get",
        params
    });
};

export const getAllCompany = params => {
    return axios({
        url: `/api/subscription_service/subjects`,
        method: "get",
        params
    });
};

export const getDetail = id => {
    return axios({
        url: `/api/subscription_service/view/` + id,
        method: "get"
    });
};
export const getEditDetail = id => {
    return axios({
        url: `/api/subscription_service/edit/info/` + id,
        method: "get"
    });
};

export const getPay = params => {
    return axios({
        url: `/api/subscription_service/payment_status`,
        method: "get",
        params
    });
};


export const getSubscribeRecord = params => {
    return axios({
        url: `/api/subscription_service/invoice/records`,
        method: "get",
        params
    });
};
export const getLoginName = params => {
    return axios({
        url: `/api/subscription_service/login_name`,
        method: "get",
        params
    });
};

