// 投标保证金审核
import axios from "../axios";



/***
 * 获取审核列表
 * @param params
 */
export const getAuditList = params => {
    return axios({
        url:  `/api/deposit_voucher/audit/list`,
        method: "get",
        params
    });
};


/***
 * 审核
 * @param params
 */
export const toAudit = data => {
    return axios({
        url:  `/api/deposit_voucher/audit/operate`,
        method: "post",
        data
    });
};

/***
 * 获取操作记录
 * @param params
 */
export const getRecordList = params => {
    return axios({
        url:  `/api/deposit_voucher/operate_record/list`,
        method: "get",
        params
    });
};

