import axios from "../axios";

/**
 * 网上采购关接口
 */


/**
 * 采购订单列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getOrderList = (params) => {
    return axios({
        url: "/api/store/orderNewList",
        method: 'get',
        params
    })
};
/**
 * 获取商城采购项目
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getProcureList = (params) => {
    return axios({
        url: "/api/store/newProcureList",
        method: 'get',
        params
    })
};
/**
 * 获取商城跳转地址
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getJumpStore = params => {
    return axios({
        url: "/api/store/skipUrl",
        method: 'get',
        params
    });
};
/**
 * 获取采购订单列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getBuyorderList = (params) => {
    return axios({
        url: "/api/store/orderList",
        method: 'get',
        params
    })
};
/**
 * 中标结果——网上采购列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getBidResultList = (params) => {
    return axios({
        url: "/mcn/" + params.pageType + "/cfzlist",
        method: 'get',
        params
    })
};

/**
 * 中标结果——新增时查询项目
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getWsProjectName = (params) => {
    return axios({
        url: "/getWsProjectName",
        method: 'get',
        params
    })
};

/**
 * 中标结果——新增时根据项目id查询标段
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getWsSectionName = (params) => {
    return axios({
        url: "/getWsSectionName",
        method: 'get',
        params
    })
};

/**
 * 中标结果——关联订单查询
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getOrderRelationInfo = (params) => {
    return axios({
        url: "/api/store/queryOrderRelationInfo",
        method: 'get',
        params
    })
};

/**
 * 订单详情
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getOrderDetailInfo = (params) => {
    return axios({
        url: "/api/store/queryOrderDetail",
        method: 'get',
        params
    })
};

/**
 * 中标结果——采购订单详情
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getProcureOrderInfo = (params) => {
    return axios({
        url: "/api/store/queryProcureOrderInfo",
        method: 'get',
        params
    })
};

/**
 * 中标结果——新增时查询页面信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getNoticeBaseInfo = (params) => {
    return axios({
        url: "/api/store/noticeBaseInfo",
        method: 'get',
        params
    })
};

/**
 * 中标结果——计划条目
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getPlanItem = (params) => {
    return axios({
        url: "/api/store/queryPlanItem",
        method: 'get',
        params
    })
};


/**
 * 中标结果——新增公告
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const saveNotice = (data) => {
    return axios({
        url: "/api/store/saveNotice",
        method: 'post',
        data
    })
};

/**
 * 中标结果——保存关联订单
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const saveRelationOrder = (data) => {
    return axios({
        url: "/api/store/saveRelationOrder",
        method: 'post',
        data
    })
};

/**
 * 选品下单
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const selectProduct = (params) => {
    return axios({
        url: "/api/store/selectProduct",
        method: 'get',
        params
    })
};
/**
 * 采购订单列表跳转商城浏览商品
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const JumpStoreProduct = (params) => {
    return axios({
        url: "/api/store/jumpStore",
        method: 'get',
        params
    })
};

/**
 * 取消下单
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const cancelProcureOrder = (data) => {
    return axios({
        url: "/api/store/cancelOrder",
        method: 'post',
        data
    })
};

/**
 * 更新订单
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const updateOrder = (data) => {
    return axios({
        url: "/api/store/updateOrder",
        method: 'post',
        data
    })
};

/**
 * 提交审核
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const submitResult = (params) => {
    return axios({
        url: `/api/cfz/store/submit/${params.noticeid}`,
        method: 'get',
        params
    })
};

/**
 * 提交审核
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const operate_authCheck = (params) => {
    return axios({
        url: `/api/store/jd/operate_auth/check`,
        method: 'get',
        params
    })
};

/**
 * 获取京东锦礼列表
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const orderlistJD = (params) => {
    return axios({
        url: `/api/jd/gift/orderlist`,
        method: 'get',
        params
    })
};
/**
 * 获取京东锦礼列表
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const refreshorder = (params) => {
    return axios({
        url: `/api/jd/gift/refreshorder`,
        method: 'get',
        params
    })
};

/**
 * 获取京东锦礼列表
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const importLucky = (data) => {
    return axios({
        url: `/api/jd/gift/import`,
        method: "post",
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
};

/**
 * 获取京东锦礼管理列表
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const ordergiftlist = params => {
    return axios({
        url: `/api/jd/gift/ordergiftlist`,
        method: "get",
        params
    })
};

/**
 * 删除京东锦礼活动信息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const deleteOrder = data => {
    return axios({
        url: `/api/jd/gift/delete`,
        method: "post",
        data
    })
};

/**
 * 提交京东锦礼活动信息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const submitOrder = data => {
    return axios({
        url: `/api/jd/gift/submit`,
        method: "post",
        data
    })
};

/**
 * 编辑京东锦礼活动信息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const updaternordergift = data => {
    return axios({
        url: `/api/jd/gift/updaternordergift`,
        method: "post",
        data
    })
};

/**
 * 提交所有京东锦礼活动信息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const submitallgiftsection = data => {
    return axios({
        url: `/api/jd/gift/submitallgiftsection`,
        method: "post",
        data
    })
};

/**
 * 新增京东锦礼活动信息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const addgiftsection = data => {
    return axios({
        url: `/api/jd/gift/addgiftsection`,
        method: "post",
        data
    })
};
