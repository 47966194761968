<!--

 @type：
 @Rely：
 @Arthur：杜玲
 @Date：2023-03-15 15:55
 -->
<template>
    <div class="slider-bottom">
        <img src="../../assets/images/layout/slider-bottom.png" style="width:260px" />
        <div class="slider-bottom-line"></div>
        <p class="slider-bottom-text-2"><img src="../../assets/images/layout/logo.png" /></p>
        <p class="slider-bottom-text-1">电子招标采购系统</p>
    </div>
</template>

<script>
    export default {
        name: "SliderBottom",
    };
</script>

<style lang="scss" scoped>

    .slider-bottom {
        width: 100%;
        height: $slider-bottom-hight;
        background: linear-gradient(360deg, #d3e1f3 0%, #ffffff 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;

        .slider-bottom-line {
            width: 100%;
            height: 0px;
            border: 1px solid #ffffff;
            margin-top: 60px;
        }
        .slider-bottom-text-1 {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            margin-top: 21px;
        }
        .slider-bottom-text-2 {
            margin-top: 29px;
            img {
                width: 160px;
                // height: 23px;
            }
        }
    }
</style>
