import axios from "../axios";
/**
 * 费用退还
 */
/**
 * 费用退还列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getList = params => {
    return axios({
        url: "/api/fee_refund/common/list",
        method: "get",
        params
    });
};

/**
 * 费用退还统计
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getStatistics = params => {
    return axios({
        url: "/api/fee_refund/common/statistics",
        method: "get",
        params
    });
};


/**
 * 退还申请
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const submitApply = data => {
    return axios({
        url: "/api/fee_refund/common/apply",
        method: "post",
        data
    });
};

/**
 * 划转申请检查账户
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getCheck = params =>{
    return axios({
        url: "/api/fee_refund/common/transfer_check",
        method: "get",
        params
    });
}


/**
 * 根据标段id获取标段信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getSectionInfo = params =>{
    return axios({
        url: "/api/project/section_info/single/view/"+params.id,
        method: "get",
        params
    });
}



/**
 * 退还-划转审核详情
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getfeeRefundAuditDetail = params =>{
    return axios({
        url: "/api/fee_refund/common/audit/detail",
        method: "get",
        params
    });
}


/**
 * 获取保证金及文件费信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getfeeRefundPayInfo = params =>{
    return axios({
        url: "/api/fee_refund/common/pay/info",
        method: "get",
        params
    });
}

/**
 * 获取所有公告信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getAllNoticeDetail = params =>{
    return axios({
        url: "/api/fee_refund/common/notice/all",
        method: "get",
        params
    });
}

