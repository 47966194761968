/**
 * 本地缓存
 * LStorage
 * sStorage
 */

const StorageType = {
    l: "localStorage",
    s: "sessionStorage"
};

class MyStorage {
    storage;
    constructor(type) {
        this.storage = type === StorageType.l ? window.localStorage : window.sessionStorage;
    }
    set(key, value, expires) {
        // 默认设置过期时间为15天，这个可以根据实际情况进行调整
        if (expires) {
            expires = new Date().getTime() + (expires === true ? 1000 * 60 * 60 * 24 * 15 : expires * 1000);
            this.storage.setItem(
                key,
                JSON.stringify({
                    value,
                    expires
                })
            );
        } else {
            this.storage.setItem(key, JSON.stringify(value));
        }
    }
    get(key) {
        const value = this.storage.getItem(key);
        if (value&&value!='undefined') {
            const data = JSON.parse(value);
            if (data && data.expires) {
                const now = new Date().getTime();
                if (data.expires && now > data.expires) {
                    this.storage.removeItem(key);
                    this.storage.setItem(key, JSON.stringify(data));
                    return null;
                }
            }
            return JSON.parse(value);
        }
        return null;
    }

    remove(key) {
        this.storage.removeItem(key);
    }
    clear() {
        this.storage.clear();
    }
}

const LStorage = new MyStorage(StorageType.l);
const SStorage = new MyStorage(StorageType.s);

export { LStorage, SStorage };
