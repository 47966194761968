import "@babel/polyfill";
// IE11 下报错，引入 'core-js/stable'; 'regenerator-runtime/runtime';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store, { setupStore } from "./store";
import "./plugins/element.js";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/theme/index.css";
import "./assets/css/index.scss";
import api from "@/api/index";
import util from "@/utils/util";
import common from "@/utils/common";
import components from "@/components/components";
import "./utils/directives";
import elementui from 'element-ui'
import {firstInputFocus} from './utils/formChecktool.js'

Vue.prototype.$firstInputFocus = firstInputFocus;

Vue.use(components);
Vue.use(api);
Vue.use(elementui);
Vue.config.productionTip = false;

setupStore();
Vue.prototype.$util = util;
Vue.prototype.$common = common;
new Vue({
    router,
    store,
    data: {
        // 空的实例放到根组件下，所有的子组件都能调用
        Bus: new Vue()
    },
    render: h => h(App)
}).$mount("#app");
