// 遴选公告
import axios from "../axios";





/***
 * 保存遴选公告
 * @param params
 */
export const saveSelectNotice = data => {
    return axios({
        url:  `/api/mcn/lx/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 编辑时获取遴选公告信息
 * @param params
 */
export const getLxNoticeDetail = data => {
    return axios({
        url:  `/api/mcn/lx/purchase/edit/notice/`+data.id,
        method: "get",
    });
};



/***
 * 删除公告
 * @param params
 */
export const deleteLXNotice = data => {
    return axios({
        url:  `/api/mcn/lx/purchase/delete/`+data.id,
        method: "get",
    });
};



/***
 * 获取遴选时间安排
 * @param params
 */
export const getLXTimeManage = data => { 
    return axios({
        url:  `/api/mcn/lx/purchase/view/schedule/`+data.id,
        method: "get",
    });
};

/***
 * 获取遴选公告新增是表单部分数据回显
 * @param params
 */
export const getLXNoticeAddInfo = data => { 
    return axios({
        url:  `/api/mcn/lx/purchase/initial_info/`+data.id,
        method: "get",
    });
};

/***
 * 编辑时获取遴选招标标段信息
 * @param params
 */
export const getLXSectionInfo = data => {
    return axios({
        url:  `/api/mcn/lx/purchase/edit/bidSection/`+data.id,
        method: "get",
    });
};
