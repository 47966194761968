import { SStorage } from "@/utils/cache-utils.js";

const MenuStore = {
    namespaced: true,
    state() {
        return {
            // currentCount: 0, //即时通讯变化
            publicKey: "",
            breadCrumbs:{},
            flowBreadCrumbs:{},
            refreshNum:0
        };
    },
    mutations: {
        setBreadCrumbs(state, arr) {
            // console.log(obj);
            state.breadCrumbs = arr || {};
            SStorage.set("breadCrumbs", arr);
        },
        setFlowBreadCrumbs(state, arr){
            state.flowBreadCrumbs = arr || {};
            SStorage.set("flowBreadCrumbs", arr);
        },
        setPublicKey(state, publicKey) {
            state.publicKey = publicKey;
        },
        toRefreshFlowMenu(state){
               state.refreshNum++
        },
    },
    actions: {
        // 保证vuex 刷新是有数据的
        loadStore({ commit, dispatch, state }) {
            const breadCrumbs = SStorage.get("breadCrumbs");
            if (breadCrumbs) {
                commit("setBreadCrumbs", breadCrumbs);
            }
            // const flowBreadCrumbs = SStorage.get("flowBreadCrumbs");
            // if (flowBreadCrumbs) {
            //     commit("setFlowBreadCrumbs", flowBreadCrumbs);
            // }
        }
    }
};
export default MenuStore;
