import axios from "../axios";


/***
 * 获取首页菜单
 * @param params
 */
export const getMenuList = params => {
    return axios({
        url: "/api/index/menus",
        method: "get",
        params
    });
};

/***
 * 获取流程菜单
 * @param params
 */
export const getFlowMenuList = params => {
    return axios({
        url: "/api/project_menu/purchaser_list",
        method: "get",
        params
    });
};
