// 中标价格库
import axios from "../axios";

/***
 * 获取澄清回复列表
 * @param params
 */
export const priceBaseList = params => {
    return axios({
        url:  `/api/bidding/biddingList`,
        method: "get",
        params
    });
};
/***
 * 中标详情
 * @param params
 */
export const biddingDetail = params => {
    return axios({
        url:  `/api/bidding/biddingDetail`,
        method: "get",
        params
    });
};