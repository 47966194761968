// 电子评标
import axios from "../axios";



/***
 * 评标项目状态及流标信息
 * @param params
 */
export const getEvaluationState = params => {
    return axios({
        url:  `/api/evaluation/message`,
        method: "get",
        params
    });
};


/***
 * 评标结果列头信息
 * @param params
 */
export const getEvaluationResultHead = params => {
    return axios({
        url:  `/api/evaluation/head`,
        method: "get",
        params
    });
};

/***
 *  获取评标结果数据
 * @param params
 */
export const getEvaluationResultData = params => {
    return axios({
        url:  `/api/evaluation/result`,
        method: "get",
        params
    });
};



/***
 *  获取评审报告文件
 * @param params
 */
export const getReviewReports = params => {
    return axios({
        url:  `/api/getReviewReports`,
        method: "get",
        params
    });
};
