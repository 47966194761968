<!--
 状态弹窗组件
 @type：component
 @依赖： {Dialog,Button} from 'element-ui'
 @Arthur 何晓波
 -->

<template>
    <el-dialog
        :append-to-body="true"
        v-if="visible"
        title=""
        :visible="visible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%"
    >
        <template v-if="type === 'success'">
            <em class="el-icon-success"></em>
        </template>
        <template v-if="type === 'error'">
            <em class="el-icon-error"></em>
        </template>
        <div class="tips">{{ title }}</div>
        <div class="text">
            {{ content }}
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button size="medium" type="primary" @click="closeDialog">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "stateDialog",
    components: {},
    data() {
        return {
            visible: false,
            type: "success",
            title: "提示",
            content: "操作成功",
            callback() {}
        };
    },
    methods: {
        showDiolog() {
            this.visible = true;
        },
        closeDialog() {
            this.visible = false;

            let cb = this.callback;
            if (cb !== null && cb !== undefined) {
                if (typeof cb !== "function") {
                    throw new Error("callback必须是一个function");
                } else {
                    cb();
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.el-dialog {
    .el-icon-success {
        display: block;
        text-align: center;
        font-size: 60px;
        color: #67c23a;
    }
    .el-icon-error {
        display: block;
        text-align: center;
        font-size: 60px;
        color: #de4740;
    }
    .tips {
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
    }
    .text {
        padding: 30px 20px 0;
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: center;
    }
    .el-dialog__footer {
        text-align: center;
        padding-bottom: 50px;
        .el-button {
            margin: 0 15px;
        }
    }
}
</style>
