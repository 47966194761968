<template>
    <div>
        <div class="Header">
            <div class="leftContent">
                <img src="../../assets/images/layout/buleLogo.png" alt=""/>
                <div class="title">四川发展招采系统</div>
            </div>
            <div class="rightContent">
                <!-- <div class="msgCenter">
                    <div class="item_i" v-for="(item, index) in funList" :key="index">
                        <i v-if="item.icon" :class="item.icon" @click="goto(item)"></i>
                    </div>
                </div> -->
                <div class="fRight">
                    <div class="useBook" @click="RecruitmentRecordVisible=true">招采备案</div>
                    <div class="useBook" @click="OperationBookVisible=true">操作手册</div>
                    <el-dropdown @command="videoHandleCommand" style="margin-right: 40px">
                        <div class="useBook">操作视频</div>
                        <el-dropdown-menu slot="dropdown" style="width: auto !important;">
                            <el-dropdown-item command="1">公开招标</el-dropdown-item>
                            <el-dropdown-item command="2">邀请招标</el-dropdown-item>
                            <el-dropdown-item command="8">公开比选</el-dropdown-item>
                            <el-dropdown-item command="6">询价采购</el-dropdown-item>
                            <el-dropdown-item command="3">库内综合比选</el-dropdown-item>
                            <el-dropdown-item command="9">库内直接比价</el-dropdown-item>
                            <el-dropdown-item command="4">竞争性谈判</el-dropdown-item>
                            <el-dropdown-item command="5">单一来源采购</el-dropdown-item>
                           
                            <el-dropdown-item command="7">遴选</el-dropdown-item>
                            
                           
                        </el-dropdown-menu>
                    </el-dropdown>

                    <el-badge :value="toDocount" :max="99" style="margin-right: 30px;">
                        <div class="tips" style="font-size: 20px;cursor: pointer;"
                             @click="$router.push({path:'/toDoIndex'})">
                            <i class="el-icon-message-solid"></i>
                        </div>
                    </el-badge>

                    <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">

                        <div class="userData">
                            <!-- <img src="../../assets/images/layout/Slice 118.png"/> -->
                              <div class="userName" style="margin-left: 20px;">
                                {{userInfo.user.userName}}&nbsp;
                              </div>
                            <div class="companyName" v-if="userInfo.currentSubject">
                                 |&nbsp;{{userInfo.currentSubject.name}}
                             </div>
                        </div>

                        <!-- <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                 </span>
                        <el-dropdown-menu slot="dropdown" style="width: auto !important;">
                            <el-dropdown-item command="setInfo">我的资料</el-dropdown-item>
                            <el-dropdown-item command="password">修改密码</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>


                    <div class="loginOut">
                        <i class="el-icon-switch-button" @click="loginOut"></i>
                    </div>
                </div>

            </div>

        </div>
        <OperationBookDialog :visible="OperationBookVisible" @close="OperationBookVisible=false"/>
        <RecruitmentRecordDialog :visible="RecruitmentRecordVisible" @close="RecruitmentRecordVisible=false"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import OperationBookDialog from './operationBookDialog.vue'; // 操作手册
    import RecruitmentRecordDialog from "./recruitmentRecord.vue"; // 招采备案弹框

    export default {
        props: {},
        computed: {
            ...mapState("loginStore", ["userInfo"]),
        },
        components: {
            OperationBookDialog,
            RecruitmentRecordDialog
        },
        data() {
            return {
                webTitle: window.globalConfig.webInfo.webTitle,
                // dbUser: { user: JSON.parse(sessionStorage.getItem('user')).user },
                funList: [{
                    "code": 'home',
                    "name": '',
                    "icon": 'iconfont icon-shouye',
                    'url': '/'
                }
                ],
                messageNum: 0,
                toDocount: 0,
                OperationBookVisible: false,
                RecruitmentRecordVisible: false
                //roleCode:""
            };
        },
        mounted() {
            this.getMessageNum();
            this.getTodoNum()
        },

        methods: {

            goto(item) {
                this.$router.push(item.url)
            },
            loginOut() {
                this.$api.common.loginOut().then(res => {
                    if (res.code == 0) {
                        this.$message.success('退出成功！');
                        sessionStorage.clear();
                        location.href = window.globalConfig.mainSiteUrl
                    }
                })
                // window.location.href = window.globalConfig.mainSiteUrl;
            },
            handleCommand(command) {
                if (command == 'setInfo') {
                    this.setInfo()
                } else if (command == 'password') {
                    this.$router.push({path: '/userInfo/passwordChange'})
                }
            },
            setInfo() {

                if (this.userInfo.currentRoles[0].code == '01') {
                    this.$router.push({path: '/userInfo/baseInfo'})
                } else if (this.userInfo.currentRoles[0].code == 'gyskgly') {
                    this.$router.push({path: '/userInfo/baseInfoGYS'})
                }
            },

            //待办数量
            getTodoNum() {
                this.$api.todo.getTodoNum().then(res => {
                    if (res.code == 0) {
                        this.toDocount = res.data.count || 0;
                    } else {
                        this.$message.error({
                            message: res.msg
                        });
                    }
                })
            },
            getMessageNum() {
                this.$api.common.getMessageNum().then(res => {
                    if (res.code == 0) {
                        this.messageNum = res.data.count || 0;
                    }
                })
            },
            videoHandleCommand(command) {
                switch (command) {
                    case "1": // 公开招标
                        this.downloadTemplateFile('7eb54c7a-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "2": // 邀请招标
                        this.downloadTemplateFile('5bba3930-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "3": // 库内综合比选
                        this.downloadTemplateFile('68b69b17-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "4": // 竞争性谈判
                        this.downloadTemplateFile('726c88a0-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "5": // 单一来源采购
                        this.downloadTemplateFile('78a42af1-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case '6': // 询价采购
                        this.downloadTemplateFile('6d7b7b1a-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "7": // 遴选
                        this.downloadTemplateFile('64375b5f-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "8": // 公开比选
                        this.downloadTemplateFile('82e9f76e-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    case "9": // 库内直接比价
                        this.downloadTemplateFile('8a62d5e6-a2fb-11ee-86ab-ee50bdf4a2d3');
                        break;
                    default:
                }
            },
            downloadTemplateFile(fileId) {
      
                let url = window.globalConfig.apiUrl[process.env.NODE_ENV] + "/api/fileInfo/downloadFile/" + fileId;
                // this.$util.get_a_header_download(url);
                window.open(url)
                
            },
        }
    };
</script>

<style lang="scss" scoped>
    .Header {
        width: 100%;
        height: $headerHeight;
        background: linear-gradient(90deg, #1664FE 0%, #3786ED 100%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0px 0 23px;
        color: #fff;
        position: relative;
        z-index: 999;

        .leftContent {
            display: flex;
            align-items: center;

            .title {
                margin-left: 5px;
                font-size: 20px;
                color: #fff;
            }

            img {
                /* width: 38px; */
                width: 70px;
                height: 36px;
                margin-top: -3px;
                margin-right: 12px;
            }
        }

        .rightContent {
            .useBook {
                color: #fff;
                font-size: 18px;
                margin-right: 26px;
                cursor: pointer;

                &:hover {
                    opacity: 0.75;
                }
            }

            @include display-flex();
            @include align-items(center);

            .msgCenter {
                @include display-flex();
                @include align-items(center);
                @include justify-content(flex-end);
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                height: $headerHeight;
                font-size: 14px;

                .item_i {
                    margin: 20px;

                    i {
                        font-size: 20px;
                        color: #fff;
                        cursor: pointer;
                    }

                    a {
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }

            .fRight {
                @include display-flex();
                @include align-items(center);
                @include justify-content(flex-end);
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                height: $headerHeight;
                padding-right: 20px;
            }

            .userData {
                @include display-flex();
                @include align-items(center);
                @include justify-content(flex-end);
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                height: $headerHeight;
                padding-right: 20px;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .userName, .companyName {
                    font-size: 14px;
                    color: #fff;
                }

                .loginOut {
                    margin-left: 34px;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.75;
                    }

                    i {
                        font-size: 20px;

                    }
                }
            }
        }
    }
</style>
