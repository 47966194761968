import axios from "axios";
import router from "@/router";
import Vue from "vue";
import { Loading, Message } from "element-ui";
import qs from "qs";

let host = window.globalConfig.apiUrl[process.env.NODE_ENV];

let loadingInstance; //loading 实例
let needLoadingRequestCount = 0; //当前正在请求的数量
/*是否有请求正在刷新token*/
let isRefreshing = false;
// 重试队列 每一项都是一个待执行待函数
let requests = [];
//重新请求的接口（限制3次）
let requestList = [];
//Loading 封装
/*
 * 打开全页loading
 * this.$showLoading()
 * */
Vue.prototype.$showLoading = function (text = "加载中...") {
    if (needLoadingRequestCount === 0) {
        loadingInstance = Loading.service({
            text: text
        });
    }
    needLoadingRequestCount++;
};
/*
 * 关闭全页loading
 * this.$closeLoading()
 * */
Vue.prototype.$closeLoading = function (type = 0) {
    needLoadingRequestCount--;
    if (type === 1 && loadingInstance) {
        loadingInstance.close();
        return false;
    }
    if (needLoadingRequestCount <= 0 && loadingInstance) {
        loadingInstance.close();
    }
};

/**
 * 刷新token
 */
function refreshToken(response, instance) {
    const refreshtoken = sessionStorage.getItem("refresh_token");
    // 判断 没有refresh_token的处理代码
    if (!refreshtoken) {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("expires_in");
        sessionStorage.removeItem("refresh_token");
        // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
    }
    let param = {
        client_id: window.g.client_id,
        client_secret: window.g.client_secret,
        grant_type: "refresh_token",
        refresh_token: refreshtoken
    };
    // instance是当前已创建的axios实例
    return instance
        .post("/connect/token", qs.stringify(param))
        .then(res => {
            //业务系统token
            sessionStorage.setItem("access_token", res.access_token);
            //业务系统token过期时间
            sessionStorage.setItem("expires_in", res.expires_in);
            //业务系统refresh_token
            sessionStorage.setItem("refresh_token", res.refresh_token);

            // 重新请求接口 前过期的接口
            response.config.headers.Authorization =
                "Bearer " + (sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : "");
            // 已经刷新了token，将所有队列中的请求进行重试
            requests.forEach(cb => cb(res.access_token));
            requests = [];
            return instance(response.config);
        })
        .catch(res => {
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("expires_in");
            sessionStorage.removeItem("refresh_token");
            // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
        })
        .finally(() => {
            isRefreshing = false;
        });
}

export default function $axios(options) {
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: options.hostType ? window.globalConfig[options.hostType][process.env.NODE_ENV] : host,
            isEditContentType: true,
            isUpload: false,
            notLoading: false,
            withCredentials: true
        });

        // request 拦截器
        instance.interceptors.request.use(
            config => {
                //有的页面执行不加载遮罩
                if (!config.notLoading) {
                 
                }
               if (config.url !== "/connect/token" && config.isEditContentType) {
                    config.headers.ContentType = "application/json;charset=UTF-8";
                } else if (config.url === "/connect/token") {
                    config.headers.ContentType = "application/x-www-form-urlencoded";
                } else if (config.isUpload) {
                    //config.headers["Content-Type"]='application/pdf'
                }
                let token = sessionStorage.getItem("access_token");
                //token='eyJhbGciOiJIUzI1NiJ9.eyJzeXNDb2RlIjoiRVAtVEhJUkRFRFRJT04iLCJzdWIiOiJ7XCJpZFwiOlwiODJkZjM1YmMtYjFmNi0xMWVhLTkwODctMDA1MDU2YjMxZGY4XCJ9IiwiZXhwIjoxNjExMzEzMDYxLCJpYXQiOjE2MTEyOTUwNjEsImp0aSI6IjY5OTA3M2E1LTA0NGUtNGMxYS04YmU1LWU3OWI1NjJiNTg2MiJ9.mTtLR8CC9ZYUqW6NoZkDJcumiKLfudmAOyBR8RwckKg'
                // 1.带上token
                if (token && !config.isUpload) {
                    config.headers.Authorization = "Bearer " + (token ? token : "");
                } else {
                    // 2.重定向到登录页面
                    //router.push('/login')
                }
                // 3. 根据请求方法，序列化传来的参数，根据后端需求是否序列化
                if (config.method === "post") {
                    Vue.prototype.$showLoading();
                    // if (config.data.__proto__ === FormData.prototype
                    //   || config.url.endsWith('path')
                    //   || config.url.endsWith('mark')
                    //   || config.url.endsWith('patchs')
                    // ) {
                    // } else {
                    //config.data = JSON.stringify(config.data)
                    // }
                } else if (config.method === "get") {
                    //get请求增加时间戳
                    let url = config.url;
                    url.indexOf("?") === -1
                        ? (config.url = url + "?_=" + new Date().getTime())
                        : (config.url = url + "&_=" + new Date().getTime());
                }
                return config;
            },

            error => {
                // console.log(error)
                Vue.prototype.$closeLoading();
                // 请求错误时
                // 1. 判断请求超时
                if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
                    // return service.request(originalRequest);// 再重复请求一次
                }
                // 2. 需要重定向到错误页面
                const errorInfo = error.response;
                if (errorInfo) {
                    error = errorInfo.data; // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
                    const errorStatus = errorInfo.status; // 404 403 500 ...
                    router.push({
                        path: `/error/${errorStatus}`
                    });
                }
                return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
            }
        );

        function tautology(data) {
            let back = new Promise(function (resolve) {
                resolve();
            });
            return back.then(function () {
                return instance(data.config);
            });
        }
        // response 拦截器
        instance.interceptors.response.use(
            response => {
           
                //有的页面执行不加载遮罩
                if (!response.config.notLoading&&response.config.method!='get') {
                    Vue.prototype.$closeLoading();
                }
                switch (response.status) {
                    case 401:
                        // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
                        break;
                    case 420:
                        // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
                        break;
                    default:
                        break;
                }
                const code = response.data.code;
                if (code === 520) {
                    //需要重新请求(最多3次)
                    let flag = false,
                        has = false;
                    requestList.forEach((item, inx) => {
                        if (item.url === response.config.url.split("?")[0]) {
                            item.num++;
                            has = true;
                            if (item.num < 3) {
                                flag = true;
                            } else {
                                requestList.splice(inx, 1);
                            }
                        }
                    });
                    if (has) {
                        if (flag) {
                            return tautology(response);
                        }
                    } else {
                        requestList.push({
                            url: response.config.url.split("?")[0],
                            num: 1
                        });
                        return tautology(response);
                    }
                }else if(code === -1){
                    Message.error({
                        message: response.data.msg ,
                    });
                }

                switch (code) {
                    case 401:
                        sessionStorage.removeItem("access_token");
                        Message.error({
                            message: response.data.msg + "。5秒后自动跳至首页！",
                            duration: 5000
                        });
                        setTimeout(() => {
                            window.location.href = window.globalConfig.mainSiteUrl;
                        }, 5000);
                        break;
                    case 403:
                    
                        Message.error({
                            message: response.data.msg + "。5秒后自动跳至首页！",
                            duration: 5000
                        });
                        setTimeout(() => {
                            window.location.href = window.globalConfig.mainSiteUrl;
                        }, 5000);
                        break;
                    case 420:
                       
                        Message.error({
                            message: response.data.msg + "。5秒后自动跳至首页！",
                            duration: 5000
                        });
                        setTimeout(() => {
                            window.location.href = window.globalConfig.mainSiteUrl;
                        }, 5000);
                        break;
                    default:
                        break;
                }
                let data;
                // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
                if (response.data === undefined) {
                    data = JSON.parse(response.request.responseText);
                } else {
                    data = response.data;
                }
                return data;
            },
            err => {
                console.log(err)
                if(err.config.method!='get'){
                    Vue.prototype.$closeLoading();
                }
                
                if (err && err.response) {
                    switch (err.response.status) {
                        case 400:
                            err.message = "请求错误";
                            break;
                        case 401:
                            err.message = "未授权，请登录";
                            sessionStorage.removeItem("access_token");
                            // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
                            break;
                        case 420:
                            err.message = "登录已过期，请重新登录";
                            // window.location.href = window.globalConfig.mainSiteUrl;//返回登陆
                            break;
                        case 403:
                            err.message = "拒绝访问";
                            break;
                        case 404:
                            err.message = `请求地址出错: ${err.response.config.url}`;
                            break;
                        case 408:
                            err.message = "请求超时";
                            break;
                        case 500:
                            err.message = "服务器内部错误";
                            break;
                        case 501:
                            err.message = "服务未实现";
                            break;
                        case 502:
                            err.message = "网关错误";
                            break;
                        case 503:
                            err.message = "服务不可用";
                            break;
                        case 504:
                            err.message = "网关超时";
                            break;
                        case 505:
                            err.message = "HTTP版本不受支持";
                            break;
                        default:
                    }
                }
                Message.error({
                    message: err.message,
                    duration: 5000
                });
                return Promise.reject(err); // 返回接口返回的错误信息
            }
        );

        // 请求处理
        instance(options)
            .then(res => {
                resolve(res);
                return false;
            })
            .catch(error => {
                reject(error);
            });
    });
}
