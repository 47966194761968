import axios from "../axios";

/**
 * 报价大厅
 */

/***
 * 通用-获取报价大厅基本信息
 * @param params
 */
export const getQuotationHallBaseInfo = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/common/base/information",
        method: "get",
        params
    });
};


/***
 * 通用-获取当前项目是否完成报价
 * @param params
 */
export const getQuotationHallIsFinish = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/common/isfinish",
        method: "get",
        params
    });
};


/***
 * 通用-获取澄清记录条数(未回复的)
 * @param params
 */
export const getClearNum = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/clear/num",
        method: "get",
        params
    });
};


/***
 * 通用-报价明细分页(成交)
 * @param params
 */
export const getQuoteDetailResultList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/deal/list",
        method: "get",
        params
    });
};

/***
 * 通用-报价明细表头
 * @param params
 */
export const getQuoteDetailHeadList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/headList",
        method: "get",
        params
    });
};

/***
 * 通用-报价明细分页(未成交)
 * @param params
 */
export const getQuoteDetailList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/nodeal/list",
        method: "get",
        params
    });
};

/***
 * 通用-完成报价
 * @param params
 */
export const completeQuote = data => {
    return axios({
        url: "/api/library_analogy_price_purcher/quote/finish",
        method: "post",
        data
    });
};

/***
 * 通用-报价开启
 * @param params
 */
export const openQuote = data => {
    return axios({
        url: "/api/library_analogy_price_purcher/quote/open",
        method: "post",
        data
    });
};

/***
 * 发起澄清
 * @param params
 */
export const addClarifica = data => {
    return axios({
        url: "/api/library_analogy_clarifica/add",
        method: "post",
        data
    });
};

/***
 * 获取澄清记录
 * @param params
 */
export const getClarificaRecord = params => {
    return axios({
        url: "/api/library_analogy_clarifica/reviewClarifications",
        method: "get",
        params
    });
};


/***
 * 获取供应商列表
 * @param params
 */
export const getSupplyList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/supply/list",
        method: "get",
        params
    });
};

/***
 * 获取澄清单位列表
 * @param params
 */
export const getClarificaUnitList = params => {
    return axios({
        url: "/api/library_analogy_clarifica/subject/showback",
        method: "get",
        params
    });
};


/***
 * 获取报价明细（某轮报价的供应商明细信息）
 * @param params
 */
export const getRoundRecordDetail = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/roundRecord/detail",
        method: "get",
        params
    });
};


/***
 * 获取报价记录表头
 * @param params
 */
export const getRoundRecordHeadList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/roundRecord/head",
        method: "get",
        params
    });
};

/***
 * 获取报价记录内容列表
 * @param params
 */
export const getRoundRecordDataList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/roundRecord/content",
        method: "get",
        params
    });
};

/***
 * 获取澄清记录列表
 * @param params
 */
export const getClarificationRecordList = params => {
    return axios({
        url: "/api/library_analogy_clarifica/reviewClarifications",
        method: "get",
        params
    });
};
/***
 * 获取报价记录条数
 * @param params
 */
export const getQuoteRecordNum = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/roundPrice/num",
        method: "get",
        params
    });
};

/***
 * 获取澄清记录条数
 * @param params
 */
export const getClearRecordNum = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/clear/num",
        method: "get",
        params
    });
};

/***
 * 获取单位列表数据
 * @param params
 */
export const getUnitList = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/subject/List",
        method: "get",
        params
    });
};



/***
 * 获取双人聊天单位列表
 * @param params
 */
export const getTwoPeopleChatMembers = params => {
    return axios({
        url: "/api/twoPeopleChat/members",
        method: "get",
        params
    });
};



/***
 * 获取双人聊天消息列表
 * @param params
 */
export const gettwoPeopleChatMessageList = params => {
    return axios({
        url: "/api/twoPeopleChat/messages",
        method: "get",
        params
    });
};


/***
 * 发送双人聊天消息
 * @param params
 */
export const sendTwoPeopleMessage = data => {
    return axios({
        url: "/api/twoPeopleChat/send/messages",
        method: "post",
        data
    });
};

/***
 * 获取系统消息列表
 * @param params
 */
export const getSystemMessageList = params => {
    return axios({
        url: "/api/systemMessage/new/list",
        method: "get",
        params
    });
};

/***
 * 获取所有人在线聊天消息列表
 * @param params
 */
export const getAllPeopleMessageList = params => {
    return axios({
        url: "/api/common/chat/messages",
        method: "get",
        params
    });
};


/***
 * 给所有人发送消息
 * @param params
 */
export const sendMeaasgeByAll = data => {
    return axios({
        url: "/api/onlineChat/send/messages",
        method: "post",
        data
    });
};

/***
 * 获取澄清详情
 * @param params
 */
export const getClarificaDetail = params => {
    return axios({
        url: "/api/library_analogy_clarifica/detail",
        method: "get",
        params
    });
};

/***
 *  获取已完成的供应商列表
 * @param params
 */
export const getSupplyListByFinish = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/supply/finish/list",
        method: "get",
        params
    });
};



/***
 *  获取供应商是否全部完成报价
 * @param params
 */
export const getSupplyIsAllFinish = params => {
    return axios({
        url: "/api/library_analogy_price_purcher/supply/all_finish",
        method: "get",
        params
    });
};


/***
 *  获取项目状态 
 * @param params
 */
export const getProjectState = params => {
    return axios({
        url: `/api/common/project/state`,
        method: "get",
        params
    });
};

/***
 *  获取报价大厅是否可以操作
 * @param params
 */
export const getIsOperate = params => {
    return axios({
        url: `/api/library_analogy_price_purcher/common/isauth/openroom`,
        method: "get",
        params
    });
};



/***
 *  导出聊天记录
 * @param params
 */
export const exportChat = params => {
    return axios({
        url: `/api/common/chat/export`,
        method: "get",
        params
    });
};

