import axios from "../axios";
/***
 * 异常、异议
 */


/***
 * 澄清回复列表
 * @param params
 */
export const clarificationreplyList = params => {
    return axios({
        url:  `/api/clarificationreply/list`,
        method: "get",
        params
    });
};

/***
 * 回复异议
 * @param params
 */
export const clarificationReply = data => {
    return axios({
        url: `/api/clarificationreply/reply`,
        method: "post",
        data
    });
};


/***
 * 新增异议
 * @param params
 */
export const editEquipmenttype = data => {
    return axios({
        url: `/api/clarificationreply/supply/add`,
        method: "post",
        data
    });
};

/***
 * 供应商查看标段下异议列表
 * @param params
 */
export const supplyList = params => {
    return axios({
        url:`/api/clarificationreply/supply/list`,
        method: "get",
        params
    });
};

/***
 * 异议详情
 * @param params
 */
export const clarificationDetail = params => {
    return axios({
        url:`/api/clarificationreply/view/${objectionId}`,
        method: "get",
        params
    });
};


/***
 * 异议列表监督人
 * @param params
 */
export const newObjectionList = params => {
    return axios({
        url:`/api/pub/objection/newObjectionList`,
        method: "get",
        params
    });
};


/***
 * 保存/编辑异议
 * @param params
 */
export const saveNewObjection = data => {
    return axios({
        url:`/api/pub/objection/saveNewObjection`,
        method: "post",
        data
    });
};
/***
 * 查看
 * @param params
 */
export const newBaseInfo = params => {
    return axios({
        url:`/api/pub/objection/NewDetailInfo`,
        method: "get",
        params
    });
};

/***
 * 回复异议回显
 * @param params
 */
export const replyNewObjection = params => {
    return axios({
        url:`/api/pub/objection/replyNewObjection`,
        method: "get",
        params
    });
};

/***
 * 采购人保存回复（草稿）
 * @param params
 */
export const replyNewHfObjection = data => {
    return axios({
        url:`/api/pub/objection/replyNewHfObjection`,
        method: "post",
        data
    });
};
/***
 * 采购人提交（保存并提交）
 * @param params
 */
export const submitNewObjection = data => {
    return axios({
        url:`/api/pub/objection/replyNewHfObjection`,
        method: "post",
        data
    });
};
/***
 * 回复异议
 * @param params
 */
export const shareInsure = data => {
    return axios({
        url:`/api/pub/objection/shareInsure`,
        method: "post",
        data
    });
};

/***
 * 分配详情
 * @param params
 */
export const shareToWho = params => {
    return axios({
        url:`/api/pub/objection/shareQuery`,
        method: "get",
        params
    });
};
