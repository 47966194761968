import { SStorage } from "@/utils/cache-utils.js";
import { TOKEN, USER_INFO, IS_LEADER, USER_DATA } from "../const/gloabl-const.js";

// 存放登录后的token及用户信息
const loginStore = {
    namespaced: true,
    state() {
        return {
            token: "",
            userInfo: {},
            userData: {},
            roleCode: "",
            isLeader: false // 是否是组长
        };
    },
    mutations: {
        // 设置登录后的数据信息
        setLoginStore(state, obj) {
            state.token = obj.token || "";
            state.userInfo = obj.userInfo || "";
            sessionStorage.setItem(TOKEN, obj.token);
            SStorage.set(USER_INFO, obj.userInfo);

        },
        // 再次重置登录后的用户信息
        setUserInfo(state, obj) {
            state.userInfo = obj.userInfo || "";
            SStorage.set(USER_INFO, obj.userInfo);
        },
        setUserData(state, obj) {
            state.userData = obj || "";
            SStorage.set(USER_DATA, obj);
        }
    },
    actions: {
        // 保证vuex 刷新是有数据的
        loadStore({ commit, dispatch, state }) {
            const token = sessionStorage.getItem(TOKEN);
            if (token) {
                const userInfo = SStorage.get(USER_INFO);
                const userData = SStorage.get(USER_DATA);
                commit("setLoginStore", {
                    token,
                    userInfo
                });
                commit("setUserData", userData);
            }
        }
    }
};
export default loginStore;
