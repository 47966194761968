// 澄清回复
import axios from "../axios";



/***
 * 获取澄清回复列表
 * @param params
 */
export const getClarifyreplyList = params => {
    return axios({
        url:  `/api/clarificationreply/list`,
        method: "get",
        params
    });
};


/***
 * 回复异议
 * @param params
 */
export const toReply = data => {
    return axios({
        url:  `/api/clarificationreply/reply`,
        method: "post",
        data
    });
};
/***
 * 获取详情
 * @param params
 */
export const getReplyDetail = params => {
    return axios({
        url:  `/api/clarificationreply/view/`+params.id,
        method: "get",
        params
    });
};

