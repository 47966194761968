import axios from "../axios";

/**
 * 待办
 */

/***
 * 待办列表
 * @param params
 */
export const getTodoList = params => {
    return axios({
        url: "/api/todo/undone/list",
        method: "get",
        params
    });
};
/***
 * 待办数量
 * @param params
 */
export const getTodoNum = params => {
    return axios({
        url: "/api/todo/undone/count",
        method: "get",
        params
    });
};

/***
 * 消息列表
 * @param params
 */
export const systemMessageList = params => {
    return axios({
        url: "/api/message/list",
        method: "get",
        params
    });
};


/***
 * 已办列表
 * @param params
 */
export const getCompleteList = params => {
    return axios({
        url: "/api/todo/done/list",
        method: "get",
        params
    });
};

/***
 * 已办数量
 * @param params
 */
export const getCompletedNum = params => {
    return axios({
        url: "/api/todo/undone/count",
        method: "get",
        params
    });
};

/***
 * 待办预处理
 * @param params
 */
export const getTodoDetails = params => {
    return axios({
        url: "/api/todo/pretreatment",
        method: "get",
        params
    });
};


/***
 * 获取组织机构
 * @param params
 */
export const getOrganization = params => {
    return axios({
        url: "/api/dept/list",
        method: "get",
        params
    });
};


/***
 * 审核
 * @param params
 */
export const submitExamine = data => {
    return axios({
        url: "/api/audit/operate",
        method: "post",
        data
    });
};


/***
 * 获取组织机构下人员
 * @param params
 */
export const searchUser = params => {
    return axios({
        url: "/api/dept/user/list",
        method: "get",
        params
    });
};

/***
 * 获取流程图
 * @param params
 */
export const getFlowChart = params => {
    return axios({
        url: "/api/audit/flow_graph",
        method: "get",
        params
    });
};


/***
 * 获取流操作记录
 * @param params
 */
export const getOperationRecord = params => {
    return axios({
        url: "/api/operation_record/list",
        method: "get",
        params
    });
};




/***
 * 获取代理选取列表
 * @param params
 */
export const getProxyList = params => {
    return axios({
        url: "/api/agency_choose/agency_project/list",
        method: "get",
        params
    });
};


/***
 * 获取代理承办人列表
 * @param params
 */
export const getAgencyList = data => {
    return axios({
        url: `/api/agency_choose/assign/search?proId=${data.procId}&page=${data.page}&pageSize=${data.pageSize}&agentPersonName=${data.agentPersonName}`,
        method: "post",
        data
    });
};
/***
 * 指定承办人
 * @param params
 */
export const appointAgent = data => {
    return axios({
        url: `/api/agency_choose/assign?procId=${data.procId}&agentIds=${data.agentIds}`,
        method: "post",
        data
    });
};

/***
 * 获取承办人
 * @param params
 */
export const getAppointAgent = data => {
    return axios({
        url: `/api/agency_choose/assign/show?proId=${data.procId}`,
        method: "post",
        data
    });
};
