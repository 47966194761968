import axios from "../axios";
/*
 * 公共使用接口模块
 */

/**
 * 二次登录params
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const secondLogin = (data, hostType = null) => {
    let url = "/api/authentication/second";
    if (data && data.subjectId) {
        url = `${url}?subjectId=${data.subjectId}`;
    }
    if (data && data.roleCode) {
        url = `${url}?roleCode=${data.roleCode}`;
    }
    if (data && data.loginType) {
        if (url.indexOf("?") !== -1) {
            url = `${url}&loginType=${data.loginType}`;
        } else {
            url = `${url}?loginType=${data.loginType}`;
        }
    }
    return axios({
        url: url,
        method: "post",
        hostType: hostType,
        data
    });
};

/**
 * 获取登录用户信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const loginInfo = params => {
    return axios({
        url: "/api/login/info",
        method: "get",
        params
    });
};



/**
 * 退出登录
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const loginOut = params => {
    return axios({
        url: "/api/login/logout",
        method: "get",
        params
    });
};

/**
 * 基本资料查看
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const checkUserInfo = params => {
    return axios({
        url: "/api/index/info/view",
        method: "get",
        params
    });
};


/**
 * 基本资料修改
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const updateUserInfo = data => {
    return axios({
        url: "/api/index/info/update",
        method: "post",
        data
    });
};
/**
 * 修改密码
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const change_pwd = data => {
    return axios({
        url: "/api/index/info/change_pwd",
        method: "post",
        data
    });
};

/**
 * 通用验证短信发送
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const verifysms = data => {
    return axios({
        url: "/api/common/verify/sms",
        method: "post",
        data
    });
};



/***
 * 通用公告列表
 * @param params
 */
export const getNoticeList = params => {
    return axios({
        url:  `/api/mcn/common/purchase/list`,
        method: "get",
        params
    });
};



/***
 * 通用可选标段列表
 * @param params
 */
export const getBidSectionList = params => {
    return axios({
        url:  `/api/mcn/common/purchase/sections`,
        method: "get",
        params
    });
};

/***
 * 通用代理机构提交公告
 * @param params
 */
export const agentSubmitNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/agent_submit`,
        method: "post",
        data
    });
};

/***
 * 通用采购人驳回公告
 * @param params
 */
export const rejectNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/reject`,
        method: "post",
        data
    });
};


/***
 * 通用公告撤回
 * @param params
 */
export const resetSubmitNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/reset_submit`,
        method: "post",
        data
    });
};

/***
 * 通用公告代理机构撤回
 * @param params
 */
export const resetAgentSubmitNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/reset_agent_submit`,
        method: "post",
        data
    });
};

/***
 * 公告通用提交审核
 * @param params
 */
export const submitNoticeAudit = data => {
    return axios({
        url:  `/api/mcn/notice_common/submit_audit`,
        method: "post",
        data
    });
};

/***
 * 通用发布公告
 * @param params
 */
export const publishNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/issue`,
        method: "post",
        data
    });
};

/***
 * 通用获取公告基本信息
 * @param params
 */
export const getNoticeBaseInfo = data => {
    return axios({
        url:  `/api/mcn/common/purchase/view/base/`+data.id,
        method: "get",
        
    });
};

/***
 * 通用获取公告富文本内容
 * @param params
 */
export const getNoticeRichTextContent = data => {
    return axios({
        url:  `/api/mcn/common/purchase/view/content/`+data.id,
        method: "get",
        
    });
};


/***
 * 通用获取公告 变更证明文件
 * @param params
 */
export const getNoticeChangeFile = data => {
    return axios({
        url:  `/api/mcn/common/purchase/view/file/`+data.id,
        method: "get",
        
    });
};

/***
 * 通用-获取公告标段信息
 * @param params
 */
export const getNoticeSectionInfo = data => {
    return axios({
        url:  `/api/mcn/common/purchase/view/section/`+data.id,
        method: "get",
        
    });
};

/***
 * 通用-获取采购人信息
 * @param params
 */
export const getPurchaserInfo = data => {
    return axios({
        url:  `/api/project/purchaser_info/view/`+data.id,
        method: "get",
        
    });
};


/***
 * 通用-修改公告（上传变更证明文件）
 * @param params
 */
export const modifyNotice = data => {
    return axios({
        url:  `/api/mcn/notice_common/modify`,
        method: "post",
        data
    });
};

/***
 * 通用-获取标段流程进展
 * @param params
 */
export const getBidSectionProcess = data => {
    return axios({
        url:  `/api/project/bid_section_process/view/`+data.id,
        method: "get"
    });
};



/***
 * 通用-获取用户配置的模板
 * @param params
 */
export const getUserTemplateList = data => {
    return axios({
        url:  `/api/templateV2/user/template`,
        method: "get",
        params:data
    });
};


/***
 * 通用-模板新增时同步数据
 * @param params
 */
export const getTemplateWithDataAdd = params => {
    return axios({
        url:  `/api/templateV2/add/getTemplateWithData`,
        method: "get",
        params
    });
};

/***
 * 通用-模板编辑时同步数据
 * @param params
 */
export const getTemplateWithDataEdit = params => {
    return axios({
        url:  `/api/templateV2/add/getTemplateWithData`,
        method: "get",
        params
    });
};

/***
 * 通用-招标标段头步统计信息
 * @param params
 */
export const getBidsectionHeadCount = params => {
    return axios({
        url:  `/api/bidsection/message/count`,
        method: "get",
        params
    });
};



/***
 * 通用-供应商分类库列表
 * @param params
 */
export const getSupplierCategoryLibraryList = params => {
    return axios({
        url:  `/api/mcn/common/category_library/list`,
        method: "get",
        params
    });
};


/***
 * 通用-获取采购公告是否展示新增按钮
 * @param params
 */
export const getShowAddBtnByNotice = params => {
    return axios({
        url:  `/api/mcn/common/purchase/add_button/show/`+params.id,
        method: "get",
        
    });
};


/***
 * 通用-获取变更补遗公告是否展示新增按钮
 * @param params
 */
export const getShowAddBtnByChangeNotice = params => {
    return axios({
        url:  `/api/mcn/common/correction/add_button/show/`+params.id,
        method: "get",
        
    });
};

/***
 * 通用-获取资料归档信息
 * @param params
 */
export const getDataArchivesInfo = params => {
    return axios({
        url:  `/api/archives/message`,
        method: "get",
        params
    });
};



/***
 * 通用-获取采购文件
 * @param params
 */
export const getPurchaseFileList = params => {
    return axios({
        url:  `/api/mcn/common/online_purchase_file/list`,
        method: "get",
        params
    });
};

/***
 * 获取前端网站配置
 * @param params
 */
export const getSiteConfig = params => {
    return axios({
        url:  `/api/common/config/front`,
        method: "get",
        params
    });
};

/***
 * 获取消息未读条数
 * @param params
 */
export const getMessageNum = params => {
    return axios({
        url:  `/api/message/unread/count`,
        method: "get",
        params
    });
};




/***
 * 已报名单位列表
 * @param params
 */
export const getRegisteredList = params => {
    return axios({
        url:  `/api/bid_section/registered/unit/list`,
        method: "get",
        params
    });
};


/***
 * 文件已递交单位列表
 * @param params
 */
export const getSubmittedFileList = params => {
    return axios({
        url:  `/api/bid_section/submitted_file/unit/list`,
        method: "get",
        params
    });
};



/***
 * 获取已交保证金单位列表
 * @param params
 */
export const getPaidDepositList = params => {
    return axios({
        url:  `/api/bid_section/paid_deposit/unit/list`,
        method: "get",
        params
    });
};


/***
 * 获取采购文件下载单位列表
 * @param params
 */
export const getDownloadFileList = params => {
    return axios({
        url:  `/api/bid_section/downloaded_file/unit/list`,
        method: "get",
        params
    });
};


/***
 * 下载文件
 * @param params
 */
export const downloadFile = url => {
    return axios({
        url,
        method: "get",
        // params,
        responseType:'blob'
    });
};

/***
 * 获取邀请供应商信息
 * @param params
 */
export const getInviteSupplier = params => {
    return axios({
        url:  `/api/mcn/common/purchase/view/supplier`,
        method: "get",
        params
    });
};

/***
 * 获取线上或者线下
 * @param params
 */
export const getBidOpenType = bidSectionId => {
    return axios({
        url:  `/api/mcn/common/purchase/tips/show/${bidSectionId}`,
        method: "get"
    });
};
