// 变更（补遗）公告
import axios from "../axios";



/***
 * 通用 变更（补遗）公告列表
 * @param params
 */
export const getChangeNoticeList = params => {
    return axios({
        url:  `/api/mcn/common/correction/list`,
        method: "get",
        params
    });
};



/***
 * 通用 变更（补遗）公告  详情查看基本信息
 * @param params
 */
export const getChangeNoticeDetailBaseInfo = params => {
    return axios({
        url:  `/api/mcn/common/correction/view/base/`+params.id,
        method: "get",
    });
};

/***
 * 通用 变更（补遗）公告  详情查看公告富文本内容
 * @param params
 */
export const getChangeNoticeDetailContent = params => {
    return axios({
        url:  `/api/mcn/common/correction/view/content/`+params.id,
        method: "get",
    });
};


/***
 * 通用 变更（补遗）公告  详情查看变更证明文件
 * @param params
 */
export const getChangeNoticeDetailChangeFile = params => {
    return axios({
        url:  `/api/mcn/common/correction/view/file/`+params.id,
        method: "get",
    });
};


/***
 * 删除 公开比选 变更（补遗）公告  
 * @param params
 */
export const deleltGKBXChangeNotice= params => {
    return axios({
        url:  `/api/mcn/gkbx/correction/delete/`+params.id,
        method: "get",
    });
};

/***
 * 获取 公开比选 变更（补遗）公告  公告信息
 * @param params
 */
export const getGKBXChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/gkbx/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 保存草稿/保存并提交 公开比选  变更（补遗）公告 
 * @param params
 */
export const saveGKBXChangeNotice= data => {
    return axios({
        url:  `/api/mcn/gkbx/correction/save`,
        method: "post",
        data
    });
};


// 公开比选

/***
 * 获取 公开比选  变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getGKBXAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/gkbx/correction/initial_info/`+data.id,
        method: "get",

    });
};

/***
 * 获取 公开比选  变更（补遗）公告  编辑信息回显
 * @param params
 */
export const getGKBXChangeNoticeBaseInfo= data => {
    return axios({
        url:  `/api/mcn/gkbx/correction/edit/notice/`+data.id,
        method: "get",

    });
};

// 库内直接比价

/***
 * 获取 库内直接比价 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getKNZJBJAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/knzjbj/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 库内直接比价  变更（补遗）公告 
 * @param params
 */
export const saveKNZJBJChangeNotice= data => {
    return axios({
        url:  `/api/mcn/knzjbj/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 库内直接比价 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getKNZJBJChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/knzjbj/correction/edit/notice/`+params.id,
        method: "get",
    });
};


/***
 * 删除 库内直接比价 变更（补遗）公告  
 * @param params
 */
export const deleltKNZJBJChangeNotice= params => {
    return axios({
        url:  `/api/mcn/knzjbj/correction/delete/`+params.id,
        method: "get",
    });
};




// 库内综合比选





/***
 * 获取 库内综合比选 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getKNZHBXAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/knzhbx/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 库内综合比选  变更（补遗）公告 
 * @param params
 */
export const saveKNZHBXChangeNotice= data => {
    return axios({
        url:  `/api/mcn/knzhbx/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 库内综合比选 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getKNZHBXChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/knzhbx/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 库内直接比价 变更（补遗）公告  
 * @param params
 */
export const deleltKNZHBXChangeNotice= params => {
    return axios({
        url:  `/api/mcn/knzhbx/correction/delete/`+params.id,
        method: "get",
    });
};










// 公开招标


/***
 * 获取 公开招标 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getGKZBAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/gkzb/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 公开招标  变更（补遗）公告 
 * @param params
 */
export const saveGKZBChangeNotice= data => {
    return axios({
        url:  `/api/mcn/gkzb/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 公开招标 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getGKZBChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/gkzb/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 公开招标 变更（补遗）公告  
 * @param params
 */
export const deleltGKZBChangeNotice= params => {
    return axios({
        url:  `/api/mcn/gkzb/correction/delete/`+params.id,
        method: "get",
    });
};













//  邀请招标


/***
 * 获取 邀请招标 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getYQZBAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/yqzb/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 邀请招标  变更（补遗）公告 
 * @param params
 */
export const saveYQZBChangeNotice= data => {
    return axios({
        url:  `/api/mcn/yqzb/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 邀请招标 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getYQZBChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/yqzb/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 邀请招标 变更（补遗）公告  
 * @param params
 */
export const deleteYQZBChangeNotice= params => {
    return axios({
        url:  `/api/mcn/yqzb/correction/delete/`+params.id,
        method: "get",
    });
};







// 询价采购


/***
 * 获取 询价采购 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getXJCGAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/xjcg/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 询价采购  变更（补遗）公告 
 * @param params
 */
export const saveXJCGChangeNotice= data => {
    return axios({
        url:  `/api/mcn/xjcg/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 询价采购 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getXJCGChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/xjcg/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 询价采购 变更（补遗）公告  
 * @param params
 */
export const deleteXJCGChangeNotice= params => {

    return axios({
        url:  `/api/mcn/xjcg/correction/delete/`+params.id,
        method: "get",
    });
};










// 竞争性谈判


/***
 * 获取 竞争性谈判 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getJZXTPAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/jzxtp/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 竞争性谈判  变更（补遗）公告 
 * @param params
 */
export const saveJZXTPChangeNotice= data => {
    return axios({
        url:  `/api/mcn/jzxtp/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 竞争性谈判 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getJZXTPChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/jzxtp/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 竞争性谈判 变更（补遗）公告  
 * @param params
 */
export const deleteJZXTPChangeNotice= params => {

    return axios({
        url:  `/api/mcn/jzxtp/correction/delete/`+params.id,
        method: "get",
    });
};
















// 单一来源采购


/***
 * 获取 单一来源采购 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getDYLYAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/dyly/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 单一来源采购  变更（补遗）公告 
 * @param params
 */
export const saveDYLYChangeNotice= data => {
    return axios({
        url:  `/api/mcn/dyly/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 单一来源采购 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getDYLYChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/dyly/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 单一来源采购 变更（补遗）公告  
 * @param params
 */
export const deleteDYLYChangeNotice= params => {

    return axios({
        url:  `/api/mcn/dyly/correction/delete/`+params.id,
        method: "get",
    });
};











// 遴选变更公告


/***
 * 获取 遴选 变更（补遗）公告  新增时初始信息回显
 * @param params
 */
export const getLXAddInitInfo= data => {
    return axios({
        url:  `/api/mcn/lx/correction/initial_info/`+data.id,
        method: "get",

    });
};


/***
 * 保存草稿/保存并提交 遴选  变更（补遗）公告 
 * @param params
 */
export const saveLXChangeNotice= data => {
    return axios({
        url:  `/api/mcn/lx/correction/save`,
        method: "post",
        data
    });
};

/***
 * 获取 遴选 变更（补遗）公告  公告信息（编辑时回显）
 * @param params
 */
export const getLXChangeNoticeInfo= params => {
    return axios({
        url:  `/api/mcn/lx/correction/edit/notice/`+params.id,
        method: "get",
    });
};

/***
 * 删除 遴选 变更（补遗）公告  
 * @param params
 */
export const deleteLXChangeNotice= params => {

    return axios({
        url:  `/api/mcn/lx/correction/delete/`+params.id,
        method: "get",
    });
};
