// 招标公告
import axios from "../axios";



/***
 * 保存公开招标公告
 * @param params
 */
export const saveTenderNotice = data => {
    return axios({
        url:  `/api/mcn/gkzb/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 编辑时获取公开招标公告信息
 * @param params
 */
export const getGKZBNoticeDetail = data => {
    return axios({
        url:  `/api/mcn/gkzb/purchase/edit/notice/`+data.id,
        method: "get",
    });
};


/***
 * 编辑时获取公开招标招标标段信息
 * @param params
 */
export const getGKZBSectionInfo = data => {
    return axios({
        url:  `/api/mcn/gkzb/purchase/edit/bidSection/`+data.id,
        method: "get",
    });
};

/***
 * 删除公告
 * @param params
 */
export const deleteGKZBNotice = data => {
    return axios({
        url:  `/api/mcn/gkzb/purchase/delete/`+data.id,
        method: "get",
    });
};



/***
 * 获取公开招标时间安排
 * @param params
 */
export const getGKZBTimeManage = data => { 
    return axios({
        url:  `/api/mcn/gkzb/purchase/view/schedule/`+data.id,
        method: "get",
    });
};

/***
 * 获取公开招标招标公告新增是表单部分数据回显
 * @param params
 */
export const getGKZBTenderNoticeAddInfo = data => { 
    return axios({
        url:  `/api/mcn/gkzb/purchase/initial_info/`+data.id,
        method: "get",
    });
};

