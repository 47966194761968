<!--
 chooseSupplier
 @type：
 @Rely：
 @Arthur：刘章红
 @Date：2023/7/13 9:22
 -->
<template>
    <div>
        <el-dialog custom-class="dialogBox" :append-to-body="false"  @close="closeDialog"
            :visible.sync="visible" width="500px">
            <div class="dialogHeader" slot="title">
                操作手册
            </div>

            <table-component :data="tableData" ref="multipleTable" :showPage="false">

                <el-table-column prop="name" label="名称">
                </el-table-column>

                <el-table-column width="80" prop="" label="操作">
                   <template slot-scope="scope">
                    <a @click="downloadFile(scope.row)">下载</a>
                   </template>
                </el-table-column>


            </table-component>
        </el-dialog>
    </div>
</template>

<script>

import TableComponent from "../../components/tableTemplate/tableComponent";
export default {
    name: "operationBookDialog",
    props: {
        visible: {
            type:Boolean,
            default:false
        }
    },
    components: { TableComponent },
    data() {
        return {
            tableData: [{
                name: '发展采购人操作手册',
                url: '/files/发展采购人操作手册.pdf'
            }, {
                name: '发展专家评审操作手册',
                url: '/files/发展专家评审操作手册.pdf'
            },{
                name: '发展供应商操作手册',
                url: '/files/发展供应商操作手册.pdf'
            },{
                name: '线上开评标系统使用常见问题解答',
                url: '/files/线上开评标系统使用常见问题解答.pdf'
            }]
        }
    },
    mounted() {
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        downloadFile(row) {
            let src = row.url
            let a = document.createElement('a');
            a.setAttribute('href', src);
            a.setAttribute('download', row.name);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

    }
}
</script>

<style scoped lang="scss"></style> 