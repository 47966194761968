const state = {
  detailRecord: [],
};

const mutations = {};

const actions = {};

export default {
  state,

  mutations,
  actions,
};
