<template>
    <Dialog :dialogVisible="visible" :title="title" width="1000px" @close="visible=false">
         <div class="richText" v-html="content">

         </div>
         <div class="dialog-footer">
            <el-button size="small" @click="NoHandleClick()">{{ btnNo }}</el-button>
            <el-button size="small" type="primary" @click="comfirmHandleClick()">{{ btnYes }}</el-button>
        </div>
    </Dialog>
  </template>
  
  <script>
  import Dialog from '@/components/Dialog.vue';
  export default {
    data(){
      return{
        visible:false,
        title: "公告预览",
        btnYes: "确 定",
        btnNo: "取 消",
        content:'',// 富文本内容
        callback() {},
        noCallBack() {}
      }
    },
    components:{
      Dialog
    },
    methods: {
        /*
         * 显示确认弹窗
         * */
        comfirmHandleClick() {
            this.visible = false;

            let cb = this.callback;
            if (cb !== null && cb !== undefined) {
                if (typeof cb !== "function") {
                    throw new Error("callback必须是一个function");
                } else {
                    cb();
                }
            }
        },
        NoHandleClick() {
            this.visible = false;

            let cb = this.noCallBack;
            if (cb !== null && cb !== undefined) {
                if (typeof cb !== "function") {
                    throw new Error("noCallBack必须是一个function");
                } else {
                    cb();
                }
            }
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .richText{
    height: 500px;
    overflow: auto;
    ::v-deep(table){
      border-collapse: collapse;
    }
   ::v-deep table td{
                border: 1px solid #DDD !important;

            }

  }
      .dialog-footer {
        display: block;
        margin-top: 40px;
        text-align: center;
        button {
            display: inline-block;
            margin-left: 22px;
        }
    }
  </style>