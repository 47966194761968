
import axios from "../axios";

/**
 * 模版
 */

/**
 * 获取项目列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const getLibraryList = params => {
    return axios({
        url: "/api/templateV2/bidding/biddingList",
        method: "get",
        params
    });
};

/**
 * 获取模版列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const getTemplateList = params => {
    return axios({
        url: "/api/templateV2/list",
        method: "get",
        params
    });
};
/**
 * 组织机构层级信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const getDepart = params => {
    return axios({
        url: "/api/templateV2/dept/DepList" ,
        method: 'get',
        params
    })
};
/**
 * 模板管理-修改模板状态
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const editEnable = data => {
    return axios({
        url: "/api/templateV2/editEnable" ,
        method: 'post',
        data
    })
};
/**
 * 模板管理-删除模版
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const deleteTemplate = id => {
    return axios({
        url: `/api/templateV2/del/${id}` ,
        method: 'get'
    })
};
/**
 * 模板管理-模版详情
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const infoTemplate = id => {
    return axios({
        url: `/api/templateV2/info/${id}` ,
        method: 'get'
    })
};
/**
 *模板管理-新增模板
 * @param params
 * @returns {*|Promise|Promise<any>}
 */

export const addTemplateInfo = data => {
    return axios({
        url: `/api/templateV2/add` ,
        method: 'post',
        data
    })
};