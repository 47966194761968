<template>
    <div class="Slider">
        <el-menu :default-active="activedMenuRouterPath" unique-opened :default-openeds="defaultOpeneds" class="el-menu-vertical-demo"
            @open="handleOpen" @close="handleClose">
            <template v-for="item in menuData">
                <!-- v-if="item.ruleCode.includes(currentRoleCode)" -->
                <template>
                    <el-submenu v-if="item.subMenus && item.subMenus.length > 0" :index="item.path" :key="item.id">
                        <template slot="title">
                            <div class="menuTitle">
                                <em :class="item.icon" />
                                <el-badge v-if="item.title=='个人事务管理(待办、已办)'" :value="toDocount">
                                    <span >{{ item.title }}</span>
                               </el-badge>

                               
                                <span v-else>{{ item.title }}</span>
                            </div>
                        </template>
                        <el-menu-item :index="it.path" v-for="(it, i) in item.subMenus" :key="i">
                            <div class="item" @click="toPage(it)">
                                {{ it.title }}
                               <span  v-if="it.title=='待办任务'&&toDocount>0"  class="redcircle"></span>
                               <span  v-if="it.title=='消息中心'&&messageNum>0" class="redcircle"></span>
                            </div>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="item.path" :key="item.id" @click="toPage(item)">
                        <em :class="item.icon"></em>
                        <span>{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>

        <slider-bottom></slider-bottom>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        mapState
    } from "vuex";
    import SliderBottom from "./SliderBottom"
    export default {
        components: {
            SliderBottom
        },
        data() {
            return {
                defaultOpeneds: ["4"],
                activedMenuRouterPath: "",
                currentRoleCode: '',
                toDocount:0,
                messageNum:0,
                menuData: [
                    // {
                    //     id: "1",
                    //     title: "采购系统首页",
                    //     path: "/",
                    //     icon: "iconfont icon-shouye1",
                    //     ruleCode: [],

                    // },
                    // {
                    //     id: "2",
                    //     title: "个人事务管理(待办、已办)",
                    //     path: "/task",
                    //     icon: "iconfont icon-daiban",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //         id: "2-1",
                    //         title: "待办任务",
                    //         path: "/toDoIndex",
                    //         icon: ""
                    //     }, {
                    //         id: "2-2",
                    //         title: "消息中心",
                    //         path: "/message",
                    //         icon: ""
                    //     }]
                    // },
                    // {
                    //     id: "3",
                    //     title: "采购计划管理",
                    //     path: "/plan",
                    //     icon: "iconfont icon-jihua",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //         id: "2-1",
                    //         title: "采购计划",
                    //         path: "/plan",
                    //         icon: ""
                    //     }]
                    // },
                    // {
                    //     id: "4",
                    //     title: "招采项目管理",
                    //     path: "",
                    //     icon: "iconfont icon-xiangmu",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //         id: "4-1",
                    //         title: "项目列表",
                    //         path: "/projectList",
                    //         icon: ""
                    //     }]
                    // },
                    // {
                    //     id: "5",
                    //     title: "补录",
                    //     path: "/additional",
                    //     icon: "iconfont icon-jieguobiao",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //             id: "5-1",
                    //             title: "进场交易项目",
                    //             path: "/additional/enter",
                    //             icon: ""
                    //         },
                    //         {
                    //             id: "5-2",
                    //             title: "零星小额采购",
                    //             path: "/additional/small",
                    //             icon: ""
                    //         },
                    //     ]
                    // },
                    // {
                    //     id: "6",
                    //     title: "采购订单",
                    //     path: "/orderForm/index",
                    //     icon: "iconfont icon-jieguobiao",
                    //     ruleCode: [],
                    //     subMenus: []
                    // },
                    // {
                    //     id: "7",
                    //     title: "招标异常情况",
                    //     path: "/err",
                    //     icon: "iconfont icon-yichang",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //         id: "7-1",
                    //         title: "投诉处理",
                    //         path: "/abnormal/complain",
                    //         icon: ""
                    //     }, {
                    //         id: "7-2",
                    //         title: "终止（异常）公告",
                    //         path: "/abnormal/terminate",
                    //         icon: ""
                    //     }]
                    // },
                    // {
                    //     id: "8",
                    //     title: "系统通用功能",
                    //     path: "/systemAbility",
                    //     icon: "iconfont icon-gongneng",
                    //     ruleCode: [],
                    //     subMenus: [{
                    //         id: "8-1",
                    //         title: "模版管理",
                    //         path: "/template/index",
                    //         icon: ""
                    //     }, {
                    //         id: "8-2",
                    //         title: "中标价格库",
                    //         path: "/price/index",
                    //         icon: ""
                    //     }]
                    // },
           
                ]
            };
        },
        computed: {
            ...mapState("menuStore", ["breadCrumbs"]),
            ...mapState("loginStore", ["userInfo"]),
            ...mapState("projectStore", ["procurementMethod"])
        },
        mounted() {
            this.activedMenu();
            this.getMenuList()
            this.getTodoNum()
            this.getMessageNum()
            // this.currentRoleCode=this.userInfo.currentRoles[0].code
        },
        methods: {
            getMenuList() {
                this.$api.menu.getMenuList().then(res => {
                    if (res.code == 0) {
                        let list = [];
                        let MenuEnum = window.globalConfig.stateConfig.menuData;
                        res.data.map((item) => {
                            let arr = [];
                            if (item.subMenus && item.subMenus.length > 0) {
                                item.subMenus.forEach(item1 => {
                                    MenuEnum.forEach(obj => {
                                        if (obj.id == item1.code) {
                                            arr.push({
                                                id: item1.id,
                                                title: item1.name,
                                                path: obj.path,
                                                icon: ''
                                            })
                                        }
                                    })
                                })
                            }
                            MenuEnum.forEach(obj => {
                                if (obj.id == item.code) {
                                    list.push({
                                        id: item.id,
                                        title: item.name,
                                        path: obj.path || item.id,
                                        icon: obj.icon,
                                        subMenus: arr
                                    })
                                }
                            })
                        });
                        this.menuData = list;
                        // menuData
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            activedMenu() {
                let routeObj = this.menuData.find(a => a.path == this.$route.path);
                if (!routeObj) {
                    let val = this.breadCrumbs;
                    if (val.path) {
                        this.activedMenuRouterPath = val.path;
                    } else {
                        routeObj = this.menuData[0];
                        this.$store.commit("menuStore/setBreadCrumbs", routeObj);
                    }
                } else {
                    this.activedMenuRouterPath = this.$route.path;
                    this.$store.commit("menuStore/setBreadCrumbs", routeObj);
                }
            },
            toPage(it) {
                this.$store.commit("menuStore/setBreadCrumbs", it);
                this.$router.push({
                    path: it.path
                });
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },

               //待办数量
        getTodoNum() {
            this.$api.todo.getTodoNum().then(res => {
                if (res.code == 0) {
                    this.toDocount = res.data.count || 0;
                } else {
                    this.$message.error({
                        message: res.msg
                    });
                }
            })
        },
        getMessageNum(){
            this.$api.common.getMessageNum().then(res=>{
                if(res.code==0){
                    this.messageNum = res.data.count || 0;
                }
            })
        },
        }
    };
</script>
<style lang="scss">
    .is-opened {
        background-color: #f5f5f5;

        &>.el-submenu__title {
            background-color: #daecff;
        }

        .el-menu {
            background-color: transparent !important;
        }
    }

    .el-menu--collapse {
        width: 0px;
    }
</style>

<style lang="scss" scoped>
    .Slider {
        text-align: center;
        height: 100%;
        padding: 14px 10px 0px 16px;

        // overflow-y: scroll;
        // overflow-x: hidden;
        position: relative;
        background: #ffffff;
        width: 270px;
.redcircle{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: red;
    margin-left: 100px;
    
}
        .el-menu {
            border-right: none;
            overflow-y: auto;
            // padding-right: 16px;
            min-height: 490px;
            max-height: calc(100vh - #{$slider-bottom-hight});
            position: relative;
            z-index: 2;
            padding-right: 5px;
           background-color: transparent;
            ::v-deep(.el-menu-item) {
                height: $slider-item-height;
                line-height: $slider-item-height;
                text-align: left;
                padding-left: 10px !important;
                padding-right: 16px !important;
                font-size: 14px;

                em {
                    font-size: 20px;
                    margin-right: 10px !important;
                    color: #9ba4ab;
                }

                &:hover {
                    color: $theme-color;

                    em {
                        color: $theme-color;
                    }
                }

                .item {
                    padding-left: 36px;
                 
                }
            }

            ::v-deep(.el-submenu__title) {
                height: 48px;
                line-height: 48px;
                text-align: left;
                padding-left: 10px !important;
                padding-right: 16px !important;

                em {
                    margin-right: 10px !important;
                }
            }

            .el-submenu .menuTitle {
                color: #303133 !important;

                em {
                    color: #9ba4ab !important;
                }
            }

            .is-active {
                background: $theme-color;
                color: #fff !important;

                .iconfont {
                    color: #fff !important;
                }

                .menuTitle {
                    color: #fff !important;

                    em {
                        color: #fff !important;
                    }
                }

                ::v-deep .el-submenu__title i {
                    color: #fff !important;
                }
                ::v-deep .el-submenu__title:hover{
                    background: none;
                   
                }
            }
        }

        ::v-deep .el-menu .is-opened {
            background: #e9f3ff !important;

            .menuTitle {
                color:$theme-color !important;

                em {
                    color: $theme-color !important;
                }
            }

            .el-submenu__title i {
                color:$theme-color !important;
            }
        }
        ::v-deep .el-badge__content.is-fixed {
            line-height: 15px !important;
        }
    }
</style>