import axios from "../axios";

export const getFileList = params => {
    return axios({
        url: `/api/OnlineInvoice/feeList`,
        method: "get",
        params
    });
};

export const getFileNumber = params => {
    return axios({
        url: `/api/OnlineInvoice/totalInfo`,
        method: "get",
        params
    });
};
export const getSubscribeList = params => {
    return axios({
        url: `/api/OnlineInvoice/subscribeFeeList`,
        method: "get",
        params
    });
};