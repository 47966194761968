// 代理选取api
import axios from "../axios";


/***
 * 代理选取显示项目列表
 * @param params
 */
export const getAgencyProjecyList = params => {
    return axios({
        url: "/api/agency_choose/list",
        method: "get",
        params
    });
};

/***
 * 代理选取
 * @param params
 */
export const agencyChoose = data => {
    return axios({
        url: "/api/agency_choose/add",
        method: "post",
        data
    });
};

/***
 * 获取代理机构列表
 * @param params
 */
export const getAgencyList = params => {
    return axios({
        url: "/api/agency_choose/agency/list",
        method: "get",
        params
    });
};

/***
 * 获取代理申请详情
 * @param params
 */
export const getAgencyApplyDetail = params => {
    return axios({
        url: "/api/agency_choose/detail",
        method: "get",
        params
    });
};


/***
 * 代理承办人指定
 * @param params
 */
export const agencyAssign = data => {
    return axios({
        url: "/api/agency_choose/assign",
        method: "post",
        data
    });
};



/***
 * 撤销
 * @param params
 */
export const cancelAgency = data => {
    return axios({
        url: "/api/agency_choose/cancel",
        method: "post",
        params:data
    });
};


/***
 * 删除代理机构
 * @param params
 */
export const deleteAgency = data => {
    return axios({
        url: "/api/agency_choose/delete",
        method: "post",
        data
    });
};

/***
 * 获取代理申请详情
 * @param params
 */
export const getAgencyProjectDetail = params => {
    return axios({
        url: "/api/agency_choose/project/detail",
        method: "get",
        params
    });
};



    /***
     * 获取代理机构入库情况
     * @param params
     */
    export const getAgencyInfo = params => {
        return axios({
            url: "/api/agency_choose/agency/info",
            method: "get",
            params
        });
    };
