import axios from "../axios";
/**
 * 在线谈判
 */

/**
 * 在线谈判列表-采购人
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getList = params => {
    return axios({
        url: "/api/purchase/negotiations",
        method: "get",
        params
    });
};
/**
 * 在线谈判内容
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getContentList = params => {
    return axios({
        url: "/api/negotiation/contents",
        method: "get",
        params,
        notLoading:true
    });
};

/**
 * 发送消息
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export const sendMsg = data => {
    return axios({
        url: "/api/negotiation/sendMsg",
        method: "post",
        data
    });
};
