// 
// 项目查询
import axios from "../axios";

/***
 * 获取项目查询列表
 * @param params
 */
export const getProjectQueryList = params => {
    return axios({
        url: `/api/project_query/list`,
        method: "get",
        params
    });
};


/***
 * 获取代理机构列表
 * @param params
 */
export const getAgencyList = params => {
    return axios({
        url: `/api/project_query/agency/list`,
        method: "get",
        params
    });
};


/***
 * 获取采购公告
 * @param params
 */
export const getPurchaseList = bidSectionId => {
    return axios({
        url: `/api/project_query/purchase/view/${bidSectionId}`,
        method: "get",
    });
};



/***
 * 获取中标候选人公告
 * @param params
 */
export const getCandidateList = bidSectionId => {
    return axios({
        url: `/api/project_query/candidate/view/${bidSectionId}`,
        method: "get",
    });
};


/***
 * 获取变更补遗公告
 * @param params
 */
export const getCorrectionList = bidSectionId => {
    return axios({
        url: `/api/project_query/correction/view/${bidSectionId}`,
        method: "get",
    });
};

/***
 * 获取结果公告公告
 * @param params
 */
export const getResultList = bidSectionId => {
    return axios({
        url: `/api/project_query/result/view/${bidSectionId}`,
        method: "get",
    });
};


/***
 * 获取终止异常公告
 * @param params
 */
export const getUnusualList = bidSectionId => {
    return axios({
        url: `/api/project_query/unusual/view/${bidSectionId}`,
        method: "get",
    });
};

/***
 * 获取合同备案信息
 * @param params
 */
export const getContractList = bidSectionId => {
    return axios({
        url: `/api/project_query/contract/view/${bidSectionId}`,
        method: "get",
    });
};


/***
 * 获取中标通知书
 * @param params
 */
export const getWinningList = bidSectionId => {
    return axios({
        url: `/api/project_query/winning/view/${bidSectionId}`,
        method: "get",
    });
};


/***
 * 获取保证金及文件费信息
 * @param params
 */
export const getPayInfo = params => {
    return axios({
        url: `/api/project_query/pay_info/view`,
        method: "get",
        params
    });
};


/***
 * 推送
 * @param params
 */
export const pushProject = params => {
    return axios({
        url: `/api/project/select/push`,
        method: "get",
        params
    });
};

