// 协同采购目录
import axios from "../axios";



/***
 * 获取协同采购目录列表
 * @param params
 */
export const getList = params => {
    return axios({
        url:  `/api/cooperation_purchase/list`,
        method: "get",
        params
    });
};


/***
 * 保存协同采购
 * @param params
 */
export const saveCollaborativePurchasing= data => {
    return axios({
        url:  `/api/cooperation_purchase/save`,
        method: "post",
        data
    });
};


/***
 * 删除协同采购目录
 * @param params
 */
export const deleteCollaborativePurchasing = params => {
    return axios({
        url:  `/api/cooperation_purchase/delete`,
        method: "post",
        data:params
    });
};

