<template>
  <div id="app">
    <router-view :key="key" />
  </div>
</template>
<script>
export default {

  computed: {
    key() {
      return this.$route.fullPath + Math.random() // 添加随机数
    },
  },
  created(){
    if (process.env.NODE_ENV != 'development'){
      this.$api.common.getSiteConfig().then(res=>{
      if(res.code=='0'){
   
        window.globalConfig={...window.globalConfig,...JSON.parse(res.data)}
       console.log(window.globalConfig)
      }
    })
    }
   
  },
}
</script>

<style lang="scss">

#app {
    width: 100%;
    height: 100%;
}
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
