/*
 * 全局组件注册文件
 * 暴露一个install方法，可在main.js中引用，记得 use
 * @Arthur 何晓波
 * */

import globalToast from "./toast/index";
import globalStateDialog from "./stateDialog/index";
import globalComfirmDialog from "./comfirmDialog/index";
import globalNoticePreviewDialog from './noticePreviewDialog/index'
export default {
    install(Vue) {
        // 注册全局组件
        // Vue.component('toast', toast)

        // 全局注册方法
        Vue.prototype.$toast = globalToast.install;
        Vue.prototype.$stateDialog = globalStateDialog.install;
        Vue.prototype.$comfirmDialog = globalComfirmDialog.install;
        Vue.prototype.$noticePreviewDialog =globalNoticePreviewDialog.install
    }
};
