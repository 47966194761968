// 开标大厅
import axios from "../axios";



/***
 * 获取开标倒计时和状态
 * @param params
 */
export const getOpenTimeAndState = params => {
    return axios({
        url:  `/api/open_hall/message`,
        method: "get",
        params
    });
};


/***
 * 获取开标一览表
 * @param params
 */
export const getOpeningResultTable = params => {
    return axios({
        url:  `/api/open_hall/table`,
        method: "get",
        params
    });
};

