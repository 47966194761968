// 谈判公告
import axios from "../axios";





/***
 * 保存谈判公告
 * @param params
 */
export const saveNegotiateNotice = data => {
    return axios({
        url:  `/api/mcn/jzxtp/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 编辑时获取谈判公告信息
 * @param params
 */
export const getJZXTPNoticeDetail = data => {
    return axios({
        url:  `/api/mcn/jzxtp/purchase/edit/notice/`+data.id,
        method: "get",
    });
};


/***
 * 编辑时获取谈判公告招标标段信息
 * @param params
 */
export const getJZXTPSectionInfo = data => {
    return axios({
        url:  `/api/mcn/jzxtp/purchase/edit/bidSection/`+data.id,
        method: "get",
    });
};

/***
 * 删除公告
 * @param params
 */
export const deleteJZXTPNotice = data => {
    return axios({
        url:  `/api/mcn/jzxtp/purchase/delete/`+data.id,
        method: "get",
    });
};



/***
 * 获取谈判公告时间安排
 * @param params
 */
export const getJZXTPTimeManage = data => { 
    return axios({
        url:  `/api/mcn/jzxtp/purchase/view/schedule/`+data.id,
        method: "get",
    });
};

/***
 * 获取谈判公告新增是表单部分数据回显
 * @param params
 */
export const getJZXTPNoticeAddInfo = data => { 
    return axios({
        url:  `/api/mcn/jzxtp/purchase/initial_info/`+data.id,
        method: "get",
    });
};

/***
 * 邀请供应商列表
 * @param params
 */
export const getJZXTPSupplierList = params => { 
    return axios({
        url:  `/api/mcn/jzxtp/invitation_supplier/list`,
        method: "get",
        params
    });
};



/***
 * 邀请供应商列表编辑时回显
 * @param params
 */
export const getJZXTPSupplierByEdit = params => { 
    return axios({
        url:  `/api/mcn/jzxtp/purchase/edit/supplier/`+params.id,
        method: "get",
        params
    });
};

