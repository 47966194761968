import Vue from "vue";




// 千分位
Vue.directive('thousands', {
    bind(el, binding, vnode) {
        const key = Object.keys(binding.modifiers)[0]
        // 处理千分位展示
        const generatingThousandthPer = (num) => {
            let num1 = num.split(".")[0], num2 = num.split(".")[1];
            let c = num1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            return num.toString().indexOf(".") !== -1 ? c + "." + num2 : c;
        }
        // 赋值
        const assignment = (event, value, value2) => {
            binding.value[key] = value;
            vnode.context.$nextTick(() => event.target.value = generatingThousandthPer(value2 || value))
        }
        // 处理最后一位非法字符
        const handlerIllegalStr = (str) => {
            while (!(/^[0-9]+.?[0-9]*/.test(str.charAt(str.length - 1)))) {
                str = str.substr(0, str.length - 1)
            }
            return str
        }

        el.addEventListener("input", (event) => {
            let inp = event.target.value = event.target.value.replace(/,/g, "")
            assignment(event, inp)
        })

        // element
        const input = $(el).find(".el-input__inner")
        if (input) {
            input[0].addEventListener("blur", (event) => {
                const val = event.target.value;
                if (!val || !/^[0-9]+.?[0-9]*/.test(val)) return;
                assignment(event, handlerIllegalStr(val.replace(/,/g, "")))
            })
        }
    }
});

// 数字输入限制
Vue.directive('limitPositive', {
    update(el, { value = 0 }, vnode) {  // 当被绑定的元素插入到 DOM 中时
        let iscancel = false;
        let num = value
        //输入操作
        const inputEvent = e => {
            if (iscancel) {
                return false;
            }
            let res = '/^(-)?\\\d*(\\\.?\\\d{0,' + num + '})/g'
            if (num) {
                e.target.value = (e.target.value.match(eval('(' + res + ')'))[0]) || null
            } else {
                e.target.value = (e.target.value.match(/^\d*/g)[0]) || null
            }

            let v = e.target.value;//为绑定的值
            vnode.data.model.callback(v);//改变虚拟节点上v-model的值
        };
        //失去焦点
        const blurEvent = e => {
            let x = e.target.value;
            // parseFloat最大长度不可超过16位，需验证长度，不做类型转换
            // let a = Math.pow(10,num) //精确小数
            // let f = Math.round(parseFloat(x) * a) / a;
            // let s = f.toString(); // 把一个逻辑值转换为字符串，并返回结果；
            let s = x.toString(); // 把一个逻辑值转换为字符串，并返回结果；
            let rs = s.indexOf('.'); // 返回某个指定的字符串值在字符串中首次出现的位置；如果字符串值没有出现，返回-1；

            if (x) {
                // 没有小数点时：
                if (rs < 0 && value != 0) {
                    rs = s.length;
                    s += '.';
                }
                while (s.length <= rs + num) {
                    s += '0';
                }
                e.target.value = s
            } else {
                e.target.value = ''
            }
        };
        el.oninput = inputEvent;
        //el-input解析出来外面包裹了一层div, 所以添加children[0]
        el.children[0].onblur = blurEvent
        //解决输入中文的问题
        el.addEventListener("compositionstart", e => {
            iscancel = true;
        });
        el.addEventListener("compositionend", e => {
            iscancel = false;
            inputEvent(e);
        });
    }
});