export default [
    {
        path: "/flowLayout/bidInvitation",
        name: "bidInvitation",
        meta: {
            keepAlive: false,
            pathName: "投标邀请书"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidInvitation/index")
    },
    {
        path: "/flowLayout/bidInvitationDetail",
        name: "bidInvitationDetail",
        meta: {
            keepAlive: false,
            pathName: "投标邀请书查看"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidInvitation/detail")
    },
    {
        path: "/flowLayout/addInvitation",
        name: "addInvitation",
        meta: {
            keepAlive: false,
            pathName: "新增邀请书"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidInvitation/addInvitation")
    },
    {
        path: "/flowLayout/changeNotice",
        name: "changeNotice",
        meta: {
            keepAlive: false,
            pathName: "变更(补遗)公告"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/changeNotice/index")
    },
    {
        path: "/flowLayout/addChangeNotice",
        name: "addChangeNotice",
        meta: {
            keepAlive: false,
            pathName: "新增变更(补遗)公告"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/changeNotice/addChangeNotice")
    },
    {
        path: "/flowLayout/changeNoticeDetail",
        name: "changeNoticeDetail",
        meta: {
            keepAlive: false,
            pathName: "变更(补遗)公告查看",

        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/changeNotice/detail")
    },
    {
        path: "/flowLayout/clarifyReply",
        name: "clarifyReply",
        meta: {
            keepAlive: false,
            pathName: "澄清回复"
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/clarifyReply/index")
    },
    {
        path: "/flowLayout/clarifyReplyDetail",
        name: "clarifyReplyDetail",
        meta: {
            keepAlive: false,
            pathName: "澄清回复查看",

        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/clarifyReply/detail")
    },
    {
        path: "/flowLayout/quotationHall",
        name: "quotationHall",
        meta: {
            keepAlive: false,
            pathName: "报价大厅",

        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/quotationHall/index")
    },
    {
        path: "/flowLayout/quotationClearDetail",
        name: "quotationClearDetail",
        meta: {
            keepAlive: false,
            pathName: "报价大厅澄清回复",

        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/quotationHall/clearDetail")
    },

    {
        path: "/terminationNotice/noticeList",
        name: "terminationNotice",
        meta: {
            keepAlive: false,
            pathName: "终止(异常)公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/terminationNotice/noticeList")
    }, {
        path: "/terminationNotice/newTerminationNotice",
        name: "newTerminationNotice",
        meta: {
            keepAlive: false,
            pathName: "新增终止(异常)公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/terminationNotice/newTerminationNotice")
    }, {
        path: "/projectDetail/index",
        name: "flowProjectDetail",
        meta: {
            keepAlive: false,
            pathName: "项目及标段信息",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/projectDetail/index")
    }, {
        path: "/terminationNotice/noticeDetail",
        name: "noticeDetail",
        meta: {
            keepAlive: false,
            pathName: "查看终止(异常)公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/terminationNotice/noticeDetail")
    }, {
        path: "/bidInfoShow/index",
        name: "bidInfoShowList",
        meta: {
            keepAlive: false,
            pathName: "查看投标信息",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidInfoShow/index")
    }, {
        path: "/bidInfoShow/paymentDetails",
        name: "paymentDetails",
        meta: {
            keepAlive: false,
            pathName: "查看缴费明细",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidInfoShow/paymentDetails")
    }, {
        path: "/contractManage/index",
        name: "contractManage",
        meta: {
            keepAlive: false,
            pathName: "合同管理",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/contractManage/index")
    }, {
        path: "/contractManage/newContract",
        name: "newContract",
        meta: {
            keepAlive: false,
            pathName: "新增合同",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/contractManage/newContract")
    }, {
        path: "/contractManage/contractDetail",
        name: "contractDetail",
        meta: {
            keepAlive: false,
            pathName: "合同详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/contractManage/contractDetail")
    },
    {
        path: "/dataArchiving/index",
        name: "contractDetail",
        meta: {
            keepAlive: false,
            pathName: "资料归档",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/dataArchiving/index")
    }, {
        path: "/bidResult/index",
        name: "bidResult",
        meta: {
            keepAlive: false,
            pathName: "中标结果",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidResult/index")
    }, {
        path: "/bidResult/addBidResult",
        name: "addBidResult",
        meta: {
            keepAlive: false,
            pathName: "新增中标结果",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidResult/addBidResult")
    }, {
        path: "/bidResult/bidResultDetail",
        name: "bidResultDetail",
        meta: {
            keepAlive: false,
            pathName: "中标结果详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidResult/bidResultDetail")
    }, {
        path: "/bidNotice/index",
        name: "bidNoticeList",
        meta: {
            keepAlive: false,
            pathName: "中标通知书",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidNotice/index")
    }, {
        path: "/bidNotice/bidNoticeDetail",
        name: "bidNoticeDetail",
        meta: {
            keepAlive: false,
            pathName: "通知书详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidNotice/bidNoticeDetail")
    }, {
        path: "/bidNotice/addBidNotice",
        name: "addBidNotice",
        meta: {
            keepAlive: false,
            pathName: "新增通知书",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidNotice/addBidNotice")
    }, {
        path: "/feeRefund/index",
        name: "feeRefund",
        meta: {
            keepAlive: false,
            pathName: "费用退还",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/feeRefund/index")
    }, {
        path: "/multipleQuotes/index",
        name: "multipleQuotes",
        meta: {
            keepAlive: false,
            pathName: "多轮报价",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/multipleQuotes/index")
    },
    {
        path: "/flowLayout/agencySelection",
        name: "agencySelection",
        meta: {
            keepAlive: false,
            pathName: "代理选取",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/agencySelection/index")
    },
    {
        path: "/flowLayout/chooseAgency",
        name: "chooseAgency",
        meta: {
            keepAlive: false,
            pathName: "代理选取/选取",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/agencySelection/chooseAgency")
    },
    {
        path: "/flowLayout/agencySelectionDetail",
        name: "agencySelectionDetail",
        meta: {
            keepAlive: false,
            pathName: "代理选取详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/agencySelection/detail")
    },
    {
        path: "/flowLayout/compareNotice",
        name: "compareNotice",
        meta: {
            keepAlive: false,
            pathName: "比选公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/compareNotice/index")
    },
    {
        path: "/flowLayout/addCompareNotice",
        name: "addCompareNotice",
        meta: {
            keepAlive: false,
            pathName: "新增比选公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/compareNotice/addCompareNotice")
    },
    {
        path: "/flowLayout/compareNoticeDetail",
        name: "compareNoticeDetail",
        meta: {
            keepAlive: false,
            pathName: "比选公告详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/compareNotice/detail")
    },
    {
        path: "/flowLayout/expertExtraction",
        name: "expertExtraction",
        meta: {
            keepAlive: false,
            pathName: "专家抽取",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/expertExtraction/index")
    },
    {
        path: "/flowLayout/openingHall",
        name: "openingHall",
        meta: {
            keepAlive: false,
            pathName: "开标大厅",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/openingHall/index")
    },
    {
        path: "/flowLayout/bidEvaluation",
        name: "bidEvaluation",
        meta: {
            keepAlive: false,
            pathName: "电子评标",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidEvaluation/index")
    },
    {
        path: "/flowLayout/onlineNegotiation",
        name: "onlineNegotiation",
        meta: {
            keepAlive: false,
            pathName: "",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/onlineNegotiation/index")
    }, {
        path: "/bidClarification/index",
        name: "bidClarification",
        meta: {
            keepAlive: false,
            pathName: "评标澄清",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidClarification/index")
    }, {
        path: "/bidClarification/replyClarification",
        name: "replyClarification",
        meta: {
            keepAlive: false,
            pathName: "回复澄清",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidClarification/replyClarification")
    }, {
        path: "/bidClarification/replyDetail",
        name: "replyDetail",
        meta: {
            keepAlive: false,
            pathName: "评标澄清查看",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidClarification/replyDetail")
    },
    {
        path: "/flowLayout/inquiryNotice",
        name: "inquiryNotice",
        meta: {
            keepAlive: false,
            pathName: "询价公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/inquiryNotice/index")
    }, {
        path: "/flowLayout/inquiryNoticeDetail",
        name: "inquiryNoticeDetail",
        meta: {
            keepAlive: false,
            pathName: "询价公告详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/inquiryNotice/detail")
    }, {
        path: "/flowLayout/addInquiryNotice",
        name: "addInquiryNotice",
        meta: {
            keepAlive: false,
            pathName: "新增询价公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/inquiryNotice/addInquiryNotice")
    },

    {
        path: "/candidatePublic/index",
        name: "candidatePublic",
        meta: {
            keepAlive: false,
            pathName: "中标候选人公示",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/candidatePublic/index")
    }, {
        path: "/candidatePublic/addCandidate",
        name: "addCandidate",
        meta: {
            keepAlive: false,
            pathName: "新建中标候选人公示",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/candidatePublic/addCandidate")
    }, {
        path: "/candidatePublic/candidateDetail",
        name: "candidateDetail",
        meta: {
            keepAlive: false,
            pathName: "中标候选人公示详情",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/candidatePublic/candidateDetail")
    },

    {
        path: "/flowLayout/tenderNotice",
        name: "tenderNotice",
        meta: {
            keepAlive: false,
            pathName: "招标公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/tenderNotice/index")
    },
    {
        path: "/flowLayout/addTenderNotice",
        name: "tenderNotice",
        meta: {
            keepAlive: false,
            pathName: "新增招标公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/tenderNotice/addTenderNotice")
    },
    {
        path: "/flowLayout/tenderNoticeDetail",
        name: "tenderNotice",
        meta: {
            keepAlive: false,
            pathName: "招标公告查看",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/tenderNotice/detail")
    },
    {
        path: "/flowLayout/negotiateNotice",
        name: "negotiateNotice",
        meta: {
            keepAlive: false,
            pathName: "谈判公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/negotiateNotice/index")
    },
    {
        path: "/flowLayout/addNegotiateNotice",
        name: "negotiateNotice",
        meta: {
            keepAlive: false,
            pathName: "新增谈判公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/negotiateNotice/addNegotiateNotice")
    },
    {
        path: "/flowLayout/negotiateNoticeDetail",
        name: "negotiateNotice",
        meta: {
            keepAlive: false,
            pathName: "谈判公告查看",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/negotiateNotice/detail")
    },

    {
        path: "/flowLayout/selectNotice",
        name: "selectNotice",
        meta: {
            keepAlive: false,
            pathName: "遴选公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/selectNotice/index")
    },
    {
        path: "/flowLayout/addSelectNotice",
        name: "addSelectNotice",
        meta: {
            keepAlive: false,
            pathName: "新增遴选公告",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/selectNotice/addSelectNotice")
    },
    {
        path: "/flowLayout/selectNoticeDetail",
        name: "selectNoticeDetail",
        meta: {
            keepAlive: false,
            pathName: "遴选公告查看",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/selectNotice/detail")
    },
    {
        path: "/onlineSupermarket/suppermarket",
        name: "suppermarket",
        meta: {
            keepAlive: false,
            pathName: "采购商城",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/onlineSupermarket/suppermarket")
    }, {
        path: "/bidSecurityReview/index",
        name: "bidSecurityReview",
        meta: {
            keepAlive: false,
            pathName: "投标保证金审核",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/bidSecurityReview/index")
    }, {
        path: "/onlineSupermarket/lucky",
        name: "lucky",
        meta: {
            keepAlive: false,
            pathName: "京东锦礼",
        },
        component: () => import(/* webpackChunkName: "lease"*/ "../views/flowPages/onlineSupermarket/lucky")
    }


]