/*
 * 接口统一集成模块
 */
/*
 * 按照模块构建后端api请求
 * */

import * as publicApi from "./moudules/common"; //公共接口模块
import * as project_approval from "./moudules/project_approval"; //立项
import * as refillResult from './moudules/refillResult' //补录
import * as abnormal from './moudules/abnormal' //异常 异议
import * as menu from './moudules/menu'
import * as plan from './moudules/plan'; //计划管理
import * as agencySelection  from './moudules/agencySelection' // 代理选取
import * as bidResult from './moudules/bidResult'; //中标结果
import * as todo from './moudules/todo'; //待办
import * as compareNotice from './moudules/compareNotice'; // 比选公告
import * as common from './moudules/common'
import * as tenderInfo from './moudules/tenderInfo' // 投标信息查看
import * as feeRefund from  './moudules/feeRefund' // 费用退还
import * as negotiations from './moudules/negotiations' // 在线谈判

import * as contract from './moudules/contract' // 合同管理

import * as changeNotice from './moudules/changeNotice' // 变更（补遗）公告
import * as clarifyReply from './moudules/clarifyReply' // 澄清回复
import * as bidClarification from './moudules/bidClarification' // 评标澄清
import * as multipleQuotes from './moudules/multipleQuotes' // 多伦报价

import * as expertExtraction from './moudules/expertExtraction' // 专家抽取
import * as evaluation from './moudules/evaluation' // 电子评标
import * as bidOpening from './moudules/bidOpening' // 开标
import * as terminationNotice from './moudules/terminationNotice' // 终止异常公告
import * as noticeApi from './moudules/noticeApi' // 通知书
import * as templateApi from './moudules/templateInfo' // 模版
import * as bidInvitation from './moudules/bidInvitation' // 投标邀请书
import * as quotationHall from './moudules/quotationHall' // 报价大厅
import * as candidateApi from './moudules/candidateApi' // 候选人公示
import * as priceApi from './moudules/priceApi' // 中标价格库
import * as inquiryNotice from './moudules/inquiryNotice' // 询价公告
import * as tenderNotice from './moudules/tenderNotice' // 招标公告
import * as purchasingFile from './moudules/purchasingFile' // 编制采购文件
import * as negotiateNotice from './moudules/negotiateNotice' // 谈判公告
import * as selectNotice from './moudules/selectNotice' // 遴选公告
import * as onlineMall from './moudules/onlineMall' // 网上商城
import * as collaborativePurchasing from './moudules/collaborativePurchasing'
import * as bidSecurityReview from './moudules/bidSecurityReview' // 投标保证金审核
import * as projectQuery from './moudules/projectQuery' // 项目查询

import * as ratingTemplate from './moudules/ratingTemplate' // 评分表
import * as subscribe from './moudules/subscribe' // 订阅
import * as invoicingUnit from './moudules/invoicingUnit' // 开票权限单位
import * as invoicingRecord from './moudules/invoicingRecord' // 开票记录

// 默认全部导出
export default {
    publicApi,
    abnormal,
    refillResult,
    menu,
    plan,
    project_approval,
    tenderInfo,
    agencySelection,
    bidResult,
    todo,
    common,
    feeRefund,
    compareNotice,
    negotiations,
    contract,
    changeNotice,
    clarifyReply,
    expertExtraction,
    evaluation,
    bidOpening,
    bidClarification,
    multipleQuotes,
    terminationNotice,
    templateApi,
    noticeApi,
    bidInvitation,
    quotationHall,
    candidateApi,
    priceApi,
    inquiryNotice,
    tenderNotice,
    purchasingFile,
    negotiateNotice,
    selectNotice,
    onlineMall,
    collaborativePurchasing,
    bidSecurityReview,
    projectQuery,
    ratingTemplate,
    subscribe,
    invoicingUnit,
    invoicingRecord
};
