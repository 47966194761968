
export function firstInputFocus() {

    setTimeout(() => {

        var isError = document.getElementsByClassName("is-error");

        let isErrorInput = isError[0].querySelector('input');

        let isErrortextarea = isError[0].querySelector('.el-textarea__inner');

        if (isErrorInput) {

            isErrorInput.focus();

        }

       else if (isErrortextarea) {

            isErrortextarea.focus();

        } else{
            isError[0].scrollIntoView()
        }
    

        console.log(isError)

    }, 100);

}
