import axios from "../axios";
/***
 * 中标结果
 */

/***
 * 结果列表页面按钮权限
 * @param params
 */
export const resultButtonShow = bidSectionId => {
    return axios({
        url: `/api/mcn/result/common/buttonShow/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 结果列表页面按钮权限 -- 网上采购
 * @param params
 */
export const resultButtonShowWSCG = bidSectionId => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/buttonShow/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 结果列表页面按钮权限 -- 库内直接比较
 * @param params
 */
export const resultButtonShowKNZJBJ = bidSectionId => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/buttonShow/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 结果列表页面按钮权限 -- 单一来源采购
 * @param params
 */
export const resultButtonShowDYLY = bidSectionId => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/buttonShow/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 结果公告列表
 * @param params
 */
export const resultCommonList = params => {
    return axios({
        url: `/api/mcn/result/common/list`,
        method: "get",
        params
    });
};
/***

 * 结果公告列表
 * @param params
 */
export const resultWSSCList = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/list`,
        method: "get",
        params
    });
};
/***
 * 删除
 * @param params
 */
export const resultCommonDelete = noticeId => {
    return axios({
        url: `/api/mcn/result/common/delete/${noticeId}`,
        method: "post"
    });
};

/***
 * 撤回提交审核
 * @param params
 */
export const resultCommonResetSubmit = data => {
    return axios({
        url: `/api/mcn/notice_common/reset_submit`,
        method: "post",
        data
    });
};

/***
 * 发布
 * @param params
 */
export const resultCommonIssue = data => {
    return axios({
        url: `/api/mcn/notice_common/issue`,
        method: "post",
        data
    });
};
/***
 * 提交审核
 * @param params
 */
export const resultCommonSubmitAudit = data => {
    return axios({
        url: `/api/mcn/notice_common/submit_audit`,
        method: "post",
        data
    });
};

/***
 * 驳回
 * @param params
 */
export const resultCommonReject = data => {
    return axios({
        url: `/api/mcn/notice_common/reject`,
        method: "post",
        data
    });
};

/***
 * 结果公告基本信息
 * @param params
 */
export const baseinfoDetail = noticeId => {
    return axios({
        url: `/api/mcn/result/common/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息--库内直接比价
 * @param params
 */
export const baseinfoDetailKNZJ = noticeId => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息 -- 查询详情
 * @param params
 */
export const baseinfoDYLY = noticeId => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息--单一来源采购--新增
 * @param params
 */
export const baseinfoDetailDYLY = params => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 结果公告基本信息--网上商城--新增
 * @param params
 */
export const baseinfoWSSC = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 结果公告基本信息--网上商城基本信息
 * @param params
 */
export const baseInfoDetailWSCG = noticeId => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息 --更正
 * @param params
 */
export const correctBaseinfoDetail = bidsectionId => {
    return axios({
        url: `/api/mcn/result/common/correct/baseinfo/${bidsectionId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息 --更正--库内直接比价
 * @param params
 */
export const correctBaseinfoDetailKNZJ = bidsectionId => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/correct/baseinfo/${bidsectionId}`,
        method: "get"
    });
};
/***
 * 结果公告基本信息--更正  --单一来源
 * @param params
 */
export const correctBaseinfoDetailDYLY = bidsectionId => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/correct/baseinfo/${bidsectionId}`,
        method: "get"
    });
};
/***
 * 结果公告新增时查看报名供应商列表--公开比选
 * @param params
 */
export const candidateCompaniesSearch = params => {
    return axios({
        url: `/api/mcn/gkbx/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--库内直接比价
 * @param params
 */
export const candidateSearchKNZJ = params => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--库内综合比选
 * @param params
 */
export const candidateSearchKNZH = params => {
    return axios({
        url: `/api/mcn/knzhbx/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--公开招标
 * @param params
 */
export const candidateSearchGKZB = params => {
    return axios({
        url: `/api/mcn/gkzb/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--竞争性谈判
 * @param params
 */
export const candidateSearchJZXTP = params => {
    return axios({
        url: `/api/mcn/jzxtp/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--遴选
 * @param params
 */
export const candidateSearchLX = params => {
    return axios({
        url: `/api/mcn/lx/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--邀请招标
 * @param params
 */
export const candidateSearchYQZB = params => {
    return axios({
        url: `/api/mcn/yqzb/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告新增时查看报名供应商列表--询价采购
 * @param params
 */
export const candidateSearchXJCG = params => {
    return axios({
        url: `/api/mcn/xjcg/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 查看全库供应商
 * @param params
 */
export const candidateAllSearch = params => {
    return axios({
        url: `/api/mcn/result/common/candidate/allSearch`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 查看全库供应商 --单一来源
 * @param params
 */
export const candidateSearchDYLY = params => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/candidate/allSearch`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 -- 单一来源 线上邀请
 * @param params
 */
export const candidateAllSearchDYLY = params => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 结果公告已保存的参选单位列表
 * @param params
 */
export const savedCandidateCompanies = noticeId => {
    return axios({
        url: `/api/mcn/result/common/candidateCompanies/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告已保存的参选单位列表
 * @param params
 */
export const savedCandidateCompaniesKNZJ = noticeId => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/candidateCompanies/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告中标单位列表
 * @param params
 */
export const savedWinningBidders = noticeId => {
    return axios({
        url: `/api/mcn/result/common/winningBidders/${noticeId}`,
        method: "get"
    });
};
/***
 * 结果公告中标单位列表--库内直接比价
 * @param params
 */
export const savedWinningBiddersKNZJ = noticeId => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/winningBidders/${noticeId}`,
        method: "get"
    });
};
/***
 * 查看已保存的报价明细
 * @param params
 */
export const quotationList = params => {
    return axios({
        url: `/api/mcn/result/common/quotationDetails`,
        method: "get",
        params
    });
};
/***
 * 查看标段计划条目信息
 * @param params
 */
export const planItemList = bidsectionId => {
    return axios({
        url: `/api/mcn/result/common/planItem/${bidsectionId}`,
        method: "get"
    });
};
/***
 * 查看标段计划条目信息==库内直接比价
 * @param params
 */
export const planItemListKNZJ = params => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/quotationDetails`,
        method: "get",
        params
    });
};
/***
 * 点击分项中标按钮时选供应商
 * @param params
 */
export const addCompaniesSearch = params => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/addCompanies/detail/search`,
        method: "get",
        params
    });
};
/***
 * 点击总价中标按钮时选供应商
 * @param params
 */
export const addCompaniesTotalSearch = params => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/addCompanies/total/search`,
        method: "get",
        params
    });
};
/***
 * 编辑或保存中标结果 --公开必选
 * @param params
 */
export const addResultNotice = data => {
    return axios({
        url: `/api/mcn/gkbx/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--库内直接比选
 * @param params
 */
export const addResultNoticeKNZJ = data => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--库内综合比选
 * @param params
 */
export const addResultNoticeKNZH = data => {
    return axios({
        url: `/api/mcn/knzhbx/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--公开招标
 * @param params
 */
export const addResultNoticeGKZB = data => {
    return axios({
        url: `/api/mcn/gkzb/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--网上采购
 * @param params
 */
export const addResultNoticeWSCG = data => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--遴选
 * @param params
 */
export const addResultNoticeLX = data => {
    return axios({
        url: `/api/mcn/lx/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--竞争性谈判
 * @param params
 */
export const addResultNoticeJZXTP = data => {
    return axios({
        url: `/api/mcn/jzxtp/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--邀请招标
 * @param params
 */
export const addResultNoticeYQZB = data => {
    return axios({
        url: `/api/mcn/yqzb/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--询价采购
 * @param params
 */
export const addResultNoticeXJCG = data => {
    return axios({
        url: `/api/mcn/xjcg/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 编辑或保存中标结果--单一来源采购
 * @param params
 */
export const addResultNoticeDYLY = data => {
    return axios({
        url: `/api/mcn/dyly/resultNotice/add`,
        method: "post",
        data
    });
};
/***
 * 新增时查询默认信息--公开必选
 * @param params
 */
export const resultNoticeBase = params => {
    return axios({
        url: `/api/mcn/gkbx/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--库内直接比选
 * @param params
 */
export const resultNoticeBaseKNZJ = params => {
    return axios({
        url: `/api/mcn/knzjbj/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--库内综合比价
 * @param params
 */
export const resultNoticeBaseKNZH = params => {
    return axios({
        url: `/api/mcn/knzhbx/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--公开招标
 * @param params
 */
export const resultNoticeBaseGKZB = params => {
    return axios({
        url: `/api/mcn/gkzb/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--竞争性谈判
 * @param params
 */
export const resultNoticeBaseJZXTP = params => {
    return axios({
        url: `/api/mcn/jzxtp/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--遴选
 * @param params
 */
export const resultNoticeBaseLX = params => {
    return axios({
        url: `/api/mcn/lx/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--邀请招标
 * @param params
 */
export const resultNoticeBaseYQZB = params => {
    return axios({
        url: `/api/mcn/yqzb/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 新增时查询默认信息--询价采购
 * @param params
 */
export const resultNoticeBaseXJCG = params => {
    return axios({
        url: `/api/mcn/xjcg/resultNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 关联订单查询列表未分页
 * @param params
 */
export const resultNoticeOrders = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/bidSection/orders`,
        method: "get",
        params
    });
};
/***
 * 获取未关联和公告已关联的订单信息
 * @param params
 */
export const resultNoticeRelateOrders = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/relate/order`,
        method: "get",
        params
    });
};
/***
 * 关联订单查询(分页)
 * @param params
 */
export const queryOrderRelationInfo = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/queryOrderRelationInfo`,
        method: "get",
        params
    });
};
/***
 * 删除公示内容
 * @param params
 */
export const resultNoticeDeleteWSCG = (noticeId,data) => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/delete/${noticeId}`,
        method: "POST",
        data
    });
};
/***
 * 订单详情-商品查询(分页)
 * @param params
 */
export const resultNoticeOrderList = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/order/list`,
        method: "get",
        params
    });
};
/***
 * 查询计划条目及条目关联订单号
 * @param params
 */
export const resultNoticeQueryPlanItem = params => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/queryPlanItem`,
        method: "get",
        params
    });
};
/***
 * 关联订单保存
 * @param params
 */
export const saveRelationOrder = data => {
    return axios({
        url: `/api/mcn/wscg/resultNotice/saveRelationOrder`,
        method: "post",
        data
    });
};
/***
 * 获取公告模版
 * @param params
 */
export const noticeTemplate = params => {
    return axios({
        url: `/api/templateV2/user/template`,
        method: "get",
        params
    });
};

/***
 * 获取公告模版
 * @param params
 */
export const templateWithData = params => {
    return axios({
        url: `/api/templateV2/edit/getTemplateWithData`,
        method: "get",
        params
    });
};

/***
 * 获取公告模版 新增获取
 * @param params
 */
export const addTemplateWithData = params => {
    return axios({
        url: `/api/templateV2/add/getTemplateWithData`,
        method: "get",
        params
    });
};



/***
 *  平台库内直接比价-结果公告保存
 * @param params
 */
export const addResultNoticeByknzjbjPlatform = data => {
    return axios({
        url: `/api/mcn/knzjbjPlatform/resultNotice/add`,
        method: "post",
        data
    });
};


/***
 * 平台库内直接比价-结果公告中标单位列表--
 * @param params
 */
export const getWinningBiddersknzjbjPlatform = noticeId => {
    return axios({
        url: `/api/mcn/result/common/winningBidders/${noticeId}`,
        method: "get"
    });
};
