// 评分表
import axios from "../axios";


/***
 * 评分表列表
 * @param params
 */
export const getScoreTemplateList = params => {
    return axios({
        url:  `/api/scoring_table/list`,
        method: "get",
        params
    });
};

/***
 * 删除评分表
 * @param params
 */
export const deleteScoreTemplate = id => {
    return axios({
        url:  `/api/scoring_table/delete/` + id,
        method: "get"
    });
};
/***
 * 添加评分表
 * @param params
 */
export const addScoreTemplate = data => {
    return axios({
        url:  `/api/scoring_table/save`,
        method: "post",
        data
    });
};

/***
 * 添加评分表
 * @param params
 */
export const getScoreTemplate = data => {
    return axios({
        url:  `/api/scoring_table/view/${data.id}`,
        method: "get"
    });
};

/***
 * 评分表模板列表
 * @param params
 */
export const getTemplateList = () => {
    return axios({
        url:  `/api/scoring_table/template/list`,
        method: "get"
    });
};


/***
 * 评分
 * @param params
 */
export const saveScore = data => {
    return axios({
        url:  `/api/mcn/contract/evaluation/save`,
        method: "post",
        data
    });
};

/***
 * 获取评分
 * @param params
 */
export const getScore = contractId => {
    return axios({
        url: `/api/mcn/contract/evaluation/view/${contractId}`,
        method: "post"
    });
};
