/*
    全局toast 注册文件
    @依赖：vue, toast
    @Arthur 何晓波
*/

import Vue from "vue";
import toast from "./toast.vue";

const ToastBox = Vue.extend(toast);

toast.install = function (options, type) {
    if (options === undefined || options === null) {
        options = {
            msg: ""
        };
    } else if (typeof options === "string" || typeof options === "number") {
        options = {
            msg: options
        };
        if (type !== undefined && options != null) {
            options.type = type;
        }
    }

    let instance = new ToastBox({
        data: options
    }).$mount();

    document.body.appendChild(instance.$el);

    Vue.nextTick(() => {
        instance.visible = true;
    });
};

export default toast;
