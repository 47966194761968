import axios from "../axios";
/**
 * 合同管理
 */

/**
 * 获取合同管理列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getList = bidSectionId => {
    return axios({
        url: `/api/mcn/contract/list/${bidSectionId}`,
        method: "get"
    });
};
/**
 * 合同上传
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export  const uploadContract = data =>{
    return axios({
        url: "/api/mcn/contract/add",
        method: "post",
        data
    });
}
/**
 * 获取合同上传回显数据
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getUploadContractDetails=params=>{
    return axios({
        url: "/api/mcn/contract/details",
        method: "get",
        params
    });
}
/**
 * 获取合同详情中基本信息
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export  const getContractBase = params=>{
    return axios({
        url: "/api/mcn/basicMessageAndStatusPerformance",
        method: "get",
        params
    });
}
/***
 * 提交履约情况
 * @param data
 * @returns {*|Promise|Promise<any>}
 */
export  const submitPerformance= data=>{
    return axios({
        url: "/api/mcn/exercise/add",
        method: "post",
        data
    });
}

/***
 * 获取履约情况列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getPerformanceList = params=>{
    return axios({
        url: "/api/mcn/statusPerformance/list",
        method: "get",
        params
    });
}
/**
 * 修改签约时间
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export  const changeTime = data=>{
    return axios({
        url: "/api/mcn/contract/signing_time/save",
        method: "post",
        data
    });
}