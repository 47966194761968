// 评标澄清
import axios from "../axios";

/***
 * 获取列表
 * @param params
 */
export const getList = params => {
    return axios({
        url:  `/api/ebid_clarification/list`,
        method: "get",
        params
    });
};

/***
 * 获取详情
 * @param params
 */
export const getDetails = params => {
    return axios({
        url:  `/api/ebid_clarification/detail`,
        method: "get",
        params
    });
};

/***
 * 回复
 * @param params
 */
export const submitReply = data => {
    return axios({
        url:  `/api/ebid_clarification/reply`,
        method: "post",
        data
    });
};