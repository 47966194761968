// 比选公告
import axios from "../axios";





/***
 * 保存公开比选公告
 * @param params
 */
export const saveCompareNotice = data => {
    return axios({
        url:  `/api/mcn/gkbx/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 编辑时获取公开比选公告信息
 * @param params
 */
export const getGkbxNoticeDetail = data => {
    return axios({
        url:  `/api/mcn/gkbx/purchase/edit/notice/`+data.id,
        method: "get",
    });
};


/***
 * 编辑时获取公开比选招标标段信息
 * @param params
 */
export const getGKBXSectionInfo = data => {
    return axios({
        url:  `/api/mcn/gkbx/purchase/edit/bidSection/`+data.id,
        method: "get",
    });
};

/***
 * 删除公告
 * @param params
 */
export const deleteGKBXNotice = data => {
    return axios({
        url:  `/api/mcn/gkbx/purchase/delete/`+data.id,
        method: "get",
    });
};



/***
 * 获取公开比选时间安排
 * @param params
 */
export const getGKBXTimeManage = data => { 
    return axios({
        url:  `/api/mcn/gkbx/purchase/view/schedule/`+data.id,
        method: "get",
    });
};

/***
 * 获取公开比选比选公告新增是表单部分数据回显
 * @param params
 */
export const getGKBXCompareNoticeAddInfo = data => { 
    return axios({
        url:  `/api/mcn/gkbx/purchase/initial_info/`+data.id,
        method: "get",
    });
};

