/*
    全局确认弹窗 注册文件
    @依赖：vue, comfirmDialog
    @Arthur 何晓波
*/

import Vue from "vue";
import noticePreviewDialog from "./noticePreviewDialog.vue";

const NoticePreviewDialog = Vue.extend(noticePreviewDialog);

noticePreviewDialog.install = function (options) {
    // if (typeof options === "string" || typeof options === "number") {
    //     options = {
    //         content: options
    //     };
    // } else if (typeof options === "function") {
    //     options = {
    //         callback: options
    //     };
    // }
    let instance = new NoticePreviewDialog({
        data: options
    }).$mount();

    document.body.appendChild(instance.$el);

    Vue.nextTick(() => {
        instance.visible = true;
    });
};

export default noticePreviewDialog;
