// 专家抽取
import axios from "../axios";



/***
 * 获取专家抽取状态和数量
 * @param params
 */
export const getExtractStateAndNum = params => {
    return axios({
        url:  `/api/expert_extract/message`,
        method: "get",
        params
    });
};


/***
 * 获取专家抽取结果
 * @param params
 */
export const getExtractResultList = params => {
    return axios({
        url:  `/api/expert_extract/results`,
        method: "get",
        params
    });
};
