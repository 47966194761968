/*
    全局状态提示框 注册文件
    @依赖：vue, stateDialog
    @Arthur 何晓波
*/

import Vue from "vue";
import stateDialog from "./stateDialog.vue";

const StateDialogBox = Vue.extend(stateDialog);

stateDialog.install = function (options, type) {
    if (options === undefined || options === null) {
        options = {
            type: "success",
            content: "操作成功"
        };
    } else if (typeof options === "string" || typeof options === "number") {
        options = {
            type: "success",
            content: options
        };
        if (type !== undefined && options != null) {
            options.type = type;
        }
    }

    let instance = new StateDialogBox({
        data: options
    }).$mount();

    document.body.appendChild(instance.$el);

    Vue.nextTick(() => {
        instance.visible = true;
    });
};

export default stateDialog;
