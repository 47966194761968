<template>
    <div class="Dialog">
        <el-dialog top="0" :visible="dialogVisible" :show-close="false" v-bind="$attrs" v-on="$listeners" :width="width" :height="height">
            <div slot="title" class="titleContent">
                <div class="title">
                    <span class="title-tip"></span>
                    <span class="title-text">{{title}}</span>
                </div>
                <i class="el-icon-close" @click="close"></i>
            </div>

            <slot></slot>


        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "标题"
        },
        dialogVisible: {
            type: Boolean,
            default: false
        },
        width:{
            type: String,
            default: "50%"
        },
        height:{
            type: String,
            default: "50%"
        }
    },
    data() {
        return {};
    },
    methods: {
        handleConfirm() {
            //点击确定按钮
            this.$emit("onConfirm");
        },
        close(){
            this.$emit('close')
        }
    }
};
</script>

<style lang="scss" scoped>
.Dialog {

	::v-deep{
		.el-dialog__wrapper{
			height: 100%;
		}
		.el-dialog{
			mix-height: 40%;
            border-radius: 4px;
            overflow: hidden;
			@include display-flex();
			@include flex-direction(column);
		}
		.el-dialog__body{
			overflow: auto;
			height: auto;
		}
	}
    ::v-deep(.el-dialog__header) {

        height: 40px;
        padding: 0 20px;
    }
    ::v-deep(.el-dialog__body) {
        padding:40px;
    }

    .titleContent {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        margin-top: 12px;
        border:none;
        .title{
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            .title-tip{
                display: inline-block;
                width: 4px;
                height: 20px;
                background: $theme-color;
                // margin-top: 4px
            }
            .title-text{
                color: #3F4144;
                margin-left: 5px;
                font-size: 14px;
            }
        }

        .el-icon-close{
            font-size: 20px;
            cursor: pointer;
            color: #898989;
            &:hover{
                opacity: 0.7;
            }
        }

    }

}
</style>
