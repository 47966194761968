<!--
 toast
 @type：component
 @依赖：
 @Arthur 何晓波
 -->
<template>
    <transition name="scale">
        <div class="toast-wrap" v-if="visible">
            <div class="toast-box" :class="type" v-if="visible" :style="styleObj">
                <em :class="'el-icon-' + type" v-if="type"></em>
                {{ msg }}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "toast",
    data() {
        return {
            msg: "",
            type: "info", // 'success','warning','error'
            time: 3000,
            visible: false,
            styleObj: {}
        };
    },
    mounted() {
        this.close();
    },
    methods: {
        close() {
            window.setTimeout(() => {
                this.visible = false;
            }, this.time);
        }
    }
};
</script>

<style lang="scss" scoped>
$theme: (
    success: (
        bgColor: #f0f9eb,
        borderColor: #e1f3d8,
        textColor: #67c23a
    ),
    error: (
        bgColor: lighten($errorColor, 42),
        borderColor: lighten($errorColor, 35),
        textColor: $errorColor
    ),
    warning: (
        bgColor: lighten($warningColor, 35),
        borderColor: lighten($warningColor, 15),
        textColor: darken($warningColor, 20)
    )
);
$fontSize: 16px;

.toast-wrap {
    width: 300px;
    height: 300px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
    position: fixed;
    z-index: 9999;
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);
}
.toast-box {
    border-radius: 6px;
    padding: 12px 20px;
    font-size: $fontSize;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);

    @each $key, $val in $theme {
        &.#{$key} {
            background: map-get(map-get($theme, $key), bgColor);
            color: map-get(map-get($theme, $key), textColor);
            border: 1px solid map-get(map-get($theme, $key), borderColor);
        }
    }
}
.scale-enter-active,
.scale-leave-active {
    transform: scale(1, 1);
    transition: all 0.3s;
}
/* .fade-leave-active below version 2.1.8 */
.scale-enter,
.scale-leave-to {
    transform: scale(0, 0);
}
</style>
