// 终止（异常）公告
import axios from "../axios";



/***
 * 获取终止（异常）公告列表
 * @param params
 */
export const getTerminationNoticeList = params => {
    return axios({
        url:  `/api/notice/termination/list`,
        method: "get",
        params
    });
};


/***
 * 终止公告保存
 * @param params
 */
export const addTerminationNotice = data => {
    return axios({
        url:  `/api/notice/termination/add`,
        method: "post",
        data
    });
};

/***
 *  删除终止公告
 * @param params
 */
export const deleteTerminationNotice = data => {
    return axios({
        url:  `/api/notice/termination/delete`,
        method: "post",
        params:data
    });
};



/***
 * 获取终止（异常）公告详情查看信息
 * @param params
 */
export const getTerminationNoticeDetailInfo = params => {
    return axios({
        url:  `/api/notice/termination/details`,
        method: "get",
        params
    });
};

/***
 * 获取终止（异常）公告编辑时信息回显
 * @param params
 */
export const getTerminationNoticeEditInfo = params => {
    return axios({
        url:  `/api/notice/termination/editMessage`,
        method: "get",
        params
    });
};

/***
 * 获取项目列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getProjectList = params =>{
    return axios({
        url:  `/api/notice/project/list`,
        method: "get",
        params
    });
}
/***
 * 获取标段列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getSectionList = params =>{
    return axios({
        url:  `/api/notice/bidSection/list`,
        method: "get",
        params
    });
}


