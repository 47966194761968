import axios from "../axios";

/**
 * 补录
 */

/***
 * 采购结果补录中的代理机构列表
 * @param params
 */
export const supplementAgencyList = params => {
    return axios({
        url: "/api/supplement/agency/list",
        method: "get",
        params
    });
};

/***
 * 删除项目
 * @param data
 */
export const supplementDelete = bidProjectId => {
    return axios({
        url: `/api/supplement/delete/${bidProjectId}`,
        method: "get",
    });
};
/***
 * 获取供应商
 * @param data
 */
export const getSupplier = params => {
    return axios({
        url: `/pub/supplier/select/data`,
        method: "get",
        params
    });
};
/***
 * 计划列表
 * @param params
 */
export const supplementPlanList = (params,openType) => {
    return axios({
        url: openType==1?'/api/plans/optional/list':"/api/supplement/planList",
        method: "get",
        params
    });
};
/***
 * 计划条目列表
 * @param params
 */
export const procurementDetails = params => {
    return axios({
        url: "/api/supplement/procurement_details",
        method: "get",
        params
    });
};

/***
 * 采购结果补录中的项目列表
 * @param params
 */
export const supplementList = params => {
    return axios({
        url: "/api/supplement/project/list",
        method: "get",
        params
    });
};


/***
 * 新增-编辑采购结果补录中的项目
 * @param params
 */
export const supplementsave = data => {
    return axios({
        url: "/api/supplement/project/save",
        method: "post",
        data
    });
};

/***
 * 项目详情
 * @param params
 */
export const supplementProjectDetail = bidProjectId => {
    return axios({
        url: `/api/supplement/projectDetail/${bidProjectId}`,
        method: "get",
    });
};


/***
 * 保存招采公示内容
 * @param params
 */
export const purchaseNoticeSave = data => {
    return axios({
        url: `/api/supplement/purchaseNotice/save`,
        method: "post",
        data
    });
};

/***
 * 招采公告详情
 * @param params
 */
export const purchaseNoticeDetail = bidProjectId => {
    return axios({
        url: `/api/supplement/purchaseNoticeDetail/${bidProjectId}`,
        method: "get"
    });
};
/***
 * 结果公告详情
 * @param params
 */
export const rersultNoticeDetailDetail = bidProjectId => {
    return axios({
        url: `/api/supplement/rersultNoticeDetail/${bidProjectId}`,
        method: "get"
    });
};
/**
 * 中标结果——查询商户或中标单位报价明细
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getSuplierPlanItem = (params) => {
    return axios({
        url: "/api/mcn/resultNotice/suplierPlanItem",
        method: 'get',
        params
    })
};
/**
 * 中标结果——查询项目计划条目
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getPlanItem = (sectionId) => {
    return axios({
        url: "/api/mcn/resultNotice/planItem/" + sectionId,
        method: 'get'
    })
}
/***
 * 保存结果公示内容
 * @param params
 */
export const resultNoticeSave = data => {
    return axios({
        url: `/api/supplement/resultNotice/save`,
        method: "post",
        data
    });
};
/***
 * 撤回
 * @param params
 */
export const resultNoticeRevoke = bidProjectId => {
    return axios({
        url: `/api/supplement/revoke/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 提交审核
 * @param params
 */
export const supplementProjectSubmit = bidProjectId => {
    return axios({
        url: `/api/supplementProject/submit/${bidProjectId}`,
        method: "get"
    });
};

 
/***
 * 结果公告详情
 * @param params
 */
export const getRersultNoticeDetail = bidProjectId => {
    return axios({
        url: `/api/supplement/rersultNoticeDetail/${bidProjectId}`,
        method: "get"
    });
};


/***
 * 零星采购列表
 * @param params
 */
export const smallProjectList = params => {
    return axios({
        url: "/api/supplement/small_project/list",
        method: "get",
        params
    });
};


/***
 * 零星采购保存草稿
 * @param params
 */
export const newSmallProject = data => {
    return axios({
        url: "/api/supplement/small_project/save",
        method: "post",
        data
    });
};

/***
 * 零星采购保存并提交
 * @param params
 */
export const newSmallSaveAndSubmit = data => {
    return axios({
        url: "/api/supplement/small_project/save_submit",
        method: "post",
        data
    });
};




/***
 * 获取零星采购
 * @param params
 */
export const getSmallProject = bidProjectId => {
    return axios({
        url: `/api/supplement/small_detail/${bidProjectId}`,
        method: "get"
    });
};

/***
 * 获取代理机构
 * @param params
 */
export const getAgencyList = params => {
    return axios({
        url: `/api/agency_choose/agency/list`,
        method: "get",
        params
    });
};



/***
 * 保存草稿
 * @param params
 */
export const saveDraft = data => {
    return axios({
        url: `/api/supplement/project/save_draft`,
        method: "post",
        data
    });
};
/***
 * 保存并提交
 * @param params
 */
export const saveAndSubmit = data => {
    return axios({
        url: `/api/supplement/project/save_submit`,
        method: "post",
        data
    });
};
/***
 * 详情
 * @param params
 */
export const getDetail = bidProjectId => {
    return axios({
        url: `/api/supplement/all_detail/${bidProjectId}`,
        method: "get"
    });
};
