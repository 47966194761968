
<template>
    <div>
        <el-dialog custom-class="dialogBox" :append-to-body="false" @close="closeDialog" :visible.sync="visible"
            width="1200px">
            <div class="dialogHeader" slot="title">
                招采备案
            </div>

            <div class="title">需报总部招采监督中心备案事项</div>

            <table-component :data="tableData" :span-method="objectSpanMethod" border ref="multipleTable" :showPage="false">

                <el-table-column type="index" label="序号" width="60">
                    <template slot-scope="scope" >
                        <div  style="color:red;text-align:center;font-weight: bold;font-size: 18px;" v-if="scope.$index==7">
                            以上备案事项，请登录OA系统进入“招采管理”模块选择对应类型进行报备。<br/><br/>
                            OA登录网址：<a style="font-weight: bold;font-size: 18px;color:red;" href="https://iam.sdholding.com/authn/base/LOCAL_UP">https://iam.sdholding.com/authn/base/LOCAL_UP</a>
                        </div>
                        <div v-else>{{scope.$index+1}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="discrib" label="情形简述">
                    <template slot-scope="scope">
                        <div v-html="scope.row.discrib" v-if="scope.row.discrib"></div>
    
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="备案时间要求"> </el-table-column>
                <el-table-column prop="type" label="OA备案模块及选择类型">
                    <template slot-scope="scope">
                        <div v-html="scope.row.type" style="line-height: 20px;"></div>
                    </template> </el-table-column>


                <el-table-column width="160" fixed="right" prop="" label="操作">
                    <template>

                        <el-image style="width: 160px; " :src="require('../../assets/images/guideimg.png')"
                            :preview-src-list="[require('../../assets/images/guideimg.png')]">
                        </el-image>
                    </template>
                </el-table-column>


            </table-component>
        </el-dialog>
    </div>
</template>

<script>

import TableComponent from "../../components/tableTemplate/tableComponent";
export default {
    name: "operationBookDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { TableComponent },
    data() {
        return {
            tableData: [
                {
                    discrib: '集中采购',
                    content: '事前备案',
                    type: 'OA-招采管理-集中采购事项备案表'
                },
                {
                    discrib: '1、50万以上的邀请招标</br></br>2、50万以上的竞争性谈判</br></br> 3、50万以上的单一来源',
                    content: '事前报告，事后5日内备案',
                    type: 'OA-招采管理-非公开招标（采购）事项备案表'
                },
                {
                    discrib: '调整采购方式',
                    content: '事前报告，事后5日内备案',
                    type: 'OA-招采管理-调整采购方式备案表'
                },
                {
                    discrib: '招采计划外事项',
                    content: '事前报告，事后5日内备案',
                    type: 'OA-招采管理-招采计划外事项备案表'
                },
                {
                    discrib: '未使用招采平台',
                    content: '事前备案',
                    type: 'OA-招采管理-未使用招采平台备案表'
                },
                {
                    discrib: '供应商建库',
                    content: '事前备案',
                    type: '1.建库前：将拟建库全套资料通过OA协同报送（协同路径：①下属企业——②招采监督中心（招采监督中心征集各部门意见后予以反馈）——③下属企业归档）</br></br>2.建库后：OA-招采管理-供应商库结果备案表'
                },
                {
                    discrib: '采购管理制度',
                    content: '事前备案',
                    type: 'OA-招采管理-采购管理制度备案表'
                },
                {
                    discrib: '',
                    content: '',
                    type: ''
                }
            ]
        }
    },
    mounted() {
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 4) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 7,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
            if(columnIndex === 0){
                if (rowIndex === 7) {
                    return {
                        rowspan: 1,
                        colspan: 5
                    };
                } else {
                    return {
                        rowspan: 1,
                        colspan: 1
                    };
                } 
            }
        }


    }
}
</script>

<style scoped lang="scss">
.title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 36px;
}
</style> 