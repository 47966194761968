// 投标邀请书
import axios from "../axios";


/***
 * 获取   库内直接比价投标邀请书 新增时 回显部分信息
 * @param params
 */
export const getKNZJBJInvitationInfoByAdd = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/initial_info/`+data.id,
        method: "get",
        
    });
};

/***
 * 保存 库内直接比价投标邀请书 投标邀请书 草稿
 * @param params
 */
export const saveKNZJBJBidInvitation = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 获取 库内直接比价投标邀请书 编辑时 回显邀请书信息
 * @param params
 */
export const getKNZJBJInvitationByEdit = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/edit/notice/`+data.id,
        method: "get",
        
    });
};

/***
 * 获取 库内直接比价投标邀请书 编辑时 回显标段信息
 * @param params
 */
export const getKNZJBJbidSectionByEdit = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/edit/bidSection/`+data.id,
        method: "get",
        
    });
};


/***
 * 获取 库内直接比价投标邀请书 编辑时 回显供应商分类库信息
 * @param params
 */
export const getKNZJBJbidSuppliercategoryLibraryByEdit = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/edit/category_library/`+data.id,
        method: "get",
        
    });
};

/***
 * 删除 库内直接比价 投标邀请书
 * @param params
 */
export const deleteKNZJBJbidInvitation = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/delete/`+data.id,
        method: "get",
        
    });
};

/***
 * 详情  查看库内直接比价 供应商分类库
 * @param params
 */
export const getKNZJBJCategoryLibraryByDetail = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/view/category_library/`+data.id,
        method: "get",
        
    });
};

/***
 * 详情  查看库内直接比价 时间安排
 * @param params
 */
export const getKNZJBJTimePlanByDetail = data => {
    return axios({
        url:  `/api/mcn/knzjbj/purchase/view/schedule/`+data.id,
        method: "get",
        
    });
};









// 库内综合比选部分


/***
 * 获取   库内综合比选投标邀请书 新增时 回显部分信息
 * @param params
 */
export const getKNZHBXInvitationInfoByAdd = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/initial_info/`+data.id,
        method: "get",
        
    });
};

/***
 *  保存 库内综合比选投标邀请书 投标邀请书 草稿
 * @param params
 */
export const saveKNZHBXBidInvitation = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/save`,
        method: "post",
        data
    });
};



/***
 * 获取 库内综合比选投标邀请书 编辑时 回显邀请书信息
 * @param params
 */
export const getKNZHBXInvitationByEdit = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/edit/notice/`+data.id,
        method: "get",
        
    });
};

/***
 * 获取 库内综合比选投标邀请书 编辑时 回显标段信息
 * @param params
 */
export const getKNZHBXbidSectionByEdit = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/edit/bidSection/`+data.id,
        method: "get",
        
    });
};


/***
 * 获取 库内综合比选投标邀请书 编辑时 回显供应商分类库信息
 * @param params
 */
export const getKNZHBXbidSuppliercategoryLibraryByEdit = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/edit/category_library/`+data.id,
        method: "get",
        
    });
};

/***
 * 删除 库内综合比选 投标邀请书
 * @param params
 */
export const deleteKNZHBXbidInvitation = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/delete/`+data.id,
        method: "get",
        
    });
};

/***
 * 详情  查看库内综合比选 供应商分类库
 * @param params
 */
export const getKNZHBXCategoryLibraryByDetail = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/view/category_library/`+data.id,
        method: "get",
        
    });
};

/***
 * 详情  查看库内综合比选 时间安排
 * @param params
 */
export const getKNZHBXTimePlanByDetail = data => {
    return axios({
        url:  `/api/mcn/knzhbx/purchase/view/schedule/`+data.id,
        method: "get",
        
    });
};










// 邀请招标

/***
 * 获取邀请招标-投标邀请书-供应商列表
 * @param params
 */
export const getYQZBSupplierList = data => {
    return axios({
        url:  `/api/mcn/yqzb/invitation_supplier/list`,
        method: "get",
        params:data
    });
};


/***
 * 删除邀请招标-投标邀请书-
 * @param params
 */
export const deleteYQZBbidInvitation = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/delete/`+data.id,
        method: "get",
       
    });
};

/***
 * 邀请招标-投标邀请书-编辑回显-招标标段信息
 * @param params
 */
export const getYQZBbidSectionByEdit = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/edit/bidSection/`+data.id,
        method: "get",
       
    });
};

/***
 * 邀请招标-投标邀请书-编辑回显-公告信息
 * @param params
 */
export const getYQZBbidNoticeInfoByEdit = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/edit/notice/`+data.id,
        method: "get",
       
    });
};


/***
 * 邀请招标-投标邀请书-编辑回显-供应商信息
 * @param params
 */
export const getYQZBSupplierInfoInfoByEdit = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/edit/supplier/`+data.id,
        method: "get",
       
    });
};


/***
 * 邀请招标-投标邀请书-新增回显-公告信息
 * @param params
 */
export const getYQZBbidNoticeInfoByAdd = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/initial_info/`+data.id,
        method: "get",
       
    });
};

/***
 * 保存邀请招标-投标邀请书
 * @param params
 */
export const saveYQZBBidInvitation = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/save`,
        method: "post",
       data
    });
};

/***
 * 邀请招标-投标邀请书-详情-时间安排
 * @param params
 */
export const getYQZBTimePlanByDetail = data => {
    return axios({
        url:  `/api/mcn/yqzb/purchase/view/schedule/`+data.id,
        method: "get",
       
    });
};



















// 单一来源

/***
 * 获取单一来源-投标邀请书-供应商列表
 * @param params
 */
export const getDYLYSupplierList = data => {
    return axios({
        url:  `/api/mcn/dyly/invitation_supplier/list`,
        method: "get",
        params:data
    });
};


/***
 * 删除单一来源-投标邀请书-
 * @param params
 */
export const deleteDYLYbidInvitation = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/delete/`+data.id,
        method: "get",
       
    });
};

/***
 * 单一来源-投标邀请书-编辑回显-招标标段信息
 * @param params
 */
export const getDYLYbidSectionByEdit = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/edit/bidSection/`+data.id,
        method: "get",
       
    });
};

/***
 * 单一来源-投标邀请书-编辑回显-公告信息
 * @param params
 */
export const getDYLYbidNoticeInfoByEdit = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/edit/notice/`+data.id,
        method: "get",
       
    });
};


/***
 * 单一来源-投标邀请书-编辑回显-供应商信息
 * @param params
 */
export const getDYLYSupplierInfoInfoByEdit = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/edit/supplier/`+data.id,
        method: "get",
       
    });
};


/***
 * 单一来源-投标邀请书-新增回显-公告信息
 * @param params
 */
export const getDYLYbidNoticeInfoByAdd = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/initial_info/`+data.id,
        method: "get",
       
    });
};

/***
 * 保存单一来源-投标邀请书
 * @param params
 */
export const saveDYLYBidInvitation = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/save`,
        method: "post",
       data
    });
};

/***
 * 单一来源-投标邀请书-详情-时间安排
 * @param params
 */
export const getDYLYTimePlanByDetail = data => {
    return axios({
        url:  `/api/mcn/dyly/purchase/view/schedule/`+data.id,
        method: "get",
       
    });
};




/***
 * 遴选-遴选公告-供应商列表
 * @param params
 */
export const getLXSupplierList = data => {
    return axios({
        url:  `/api/mcn/lx/invitation_supplier/list`,
        method: "get",
        params:data
    });
};




/***
 * 遴选-遴选公告-编辑回显-供应商信息
 * @param params
 */
export const getLXSupplierInfoInfoByEdit = data => {
    return axios({
        url:  `/api/mcn/lx/purchase/edit/supplier/`+data.id,
        method: "get",
       
    });
};