<template>
    <div class="BreadcrumbsCard">
        <!-- <em class="iconfont icon-dailiren"></em>
        <span>{{breadCrumbs.title}}</span>
        <a class="colse" @click="$router.go(-1)">x</a> -->
        <div class="addhead">
            <div class="title">{{title?title:breadCrumbs.title}}</div>
            <div class="close" @click="$router.go(-1)">
                <i class="el-icon-close"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
export default {
    data() {
        return {}
    },
    props:{
        title:{
            type:String,
            default:''
        }
    },
    computed: {
        ...mapState('menuStore',['breadCrumbs'])
    },
    methods:{
        back(){
            history.back()
        },
    }
}
</script>

<style lang="scss" scoped>
.BreadcrumbsCard {
    // width: 100%;
    // height: $breadcrumbs-hight;
    // color: $theme-color;
    // margin-bottom: 5px;
    // em{
    //     font-size: 18px;
    //     color: $theme-color;
    // }
    // span{
    //     font-size: 14px;
    //     margin-left: 3px;
    // }
    // .colse{
    //     float: right;
    //     cursor: pointer;
    //     display: inline-block;
    //     margin-right: 20px;
    //     width: 30px;
    //     height: 30px;
    //     line-height: 30px;
    //     text-align: center;
    //     font-size: 20px;
    //     color:#898989 ;
    //     &:hover{
    //         background: #ebebeb;
    //     }
    // }
    .addhead {
        display: flex;
        justify-content: space-between;
        align-items: center;
margin-bottom: 10px;
        .title {
            font-size: 16px;
            color: #666666;
        }

        .close {
            cursor: pointer;

            &:hover {
                opacity: 0.75;
            }

            i {
                font-size: 18px;

            }
        }
    }
}
</style>
