// 询价公告
import axios from "../axios";



/***
 * 保存询价采购公告
 * @param params
 */
export const saveInquiryNotice = data => {
    return axios({
        url:  `/api/mcn/xjcg/purchase/save`,
        method: "post",
        data
    });
};


/***
 * 编辑时获取询价采购公告信息
 * @param params
 */
export const getXJCGNoticeDetail = data => {
    return axios({
        url:  `/api/mcn/xjcg/purchase/edit/notice/`+data.id,
        method: "get",
    });
};


/***
 * 编辑时获取询价采购招标标段信息
 * @param params
 */
export const getXJCGSectionInfo = data => {
    return axios({
        url:  `/api/mcn/xjcg/purchase/edit/bidSection/`+data.id,
        method: "get",
    });
};

/***
 * 删除公告
 * @param params
 */
export const deleteXJCGNotice = data => {
    return axios({
        url:  `/api/mcn/xjcg/purchase/delete/`+data.id,
        method: "get",
    });
};



/***
 * 获取询价采购时间安排
 * @param params
 */
export const getXJCGTimeManage = data => { 
    return axios({
        url:  `/api/mcn/xjcg/purchase/view/schedule/`+data.id,
        method: "get",
    });
};

/***
 * 获取询价采购招标公告新增是表单部分数据回显
 * @param params
 */
export const getXJCGNoticeAddInfo = data => { 
    return axios({
        url:  `/api/mcn/xjcg/purchase/initial_info/`+data.id,
        method: "get",
    });
};

