<!--
 tableComponent
 @type：
 @Rely：
 @Arthur：刘章红
 @Date：2023/7/19 16:06
 -->
<template>
    <div class="tablePanel"   >
        <!-- v-on="$listeners" -->
        <el-table
                ref="Table"
                v-bind="$attrs"
                :height="tableHeight"
                :max-height="maxHeight"
                :data="data"
                style="width:100%"
                :cell-class-name='cellClassName'
                :span-method="spanMethod"
                @row-click='rowClick'
                @selection-change="handleSelectionChange"
                :cell-style="{ color: '#414548', fontWeight: '500' }"
                :header-cell-style="{
        background: '#F7F8FC',
        height: '48px',
        color: '#606266',
        fontWeight: '400',
        fontSize:'14px'
      }"
        >
            <slot ></slot>
        </el-table>

        <div class="paginationContent" v-if="showPage">
            <!-- <div class="total">共<span>{{total}}</span>条</div> -->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :total="total"
                    :page-size='pageSize'
                    :current-page='currentPage'
                    layout="total,prev, pager, next,sizes"

            >

            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "tableComponent",
        props: {
            data: {
                //表格的数据
                type: Array,
                default: function () {
                    return [];
                },
            },
            fullScreen:{
                type:Boolean,
                default:false
            },
            showPage: {
                //是否展示分页
                type: Boolean,
                default: true,
            },
            pageSize:{
                type:Number,
                default:10
            },
            currentPage:{
                type:Number,
                defalut:1
            },
            total:{  //数据总条数
                type:Number,
                default:0
            },
            tableHeight:String,
            maxHeight:String,
            spanMethod:{},
            cellClassName:Function
        },
        data() {
            return {}
        },
        mounted() {
        },
        computed: {
            // 计算总页数
            totalPages: {
                get() {
                    return Math.round(Math.ceil(this.total / this.pageSize));
                }
            }
        },
        methods: {
            HandleSizeChange() {},
            handleSizeChange(e){
                this.$emit('size-change',e)
            },
            handleCurrentChange(e){
                this.$emit('current-change',e)
            },
            rowClick(e){
                this.$emit('row-click',e)
            },
           /* spanMethod(e){
                this.$emit('span-method',e)
            },*/
            handleSelectionChange(e){
                this.$emit('selection-change',e)
            },
            toggleRowSelection(row){
                this.$refs.Table.toggleRowSelection(row);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .tablePanel {
        ::v-deep .el-table th {
            color: #606266 !important;
        }
        .el-table td.el-table__cell {
            border-color:#e5e5e5;
        }
        ::v-deep .el-table td.el-table__cell {
            height: 56px !important;
        }

        ::v-deep .el-table .el-table__header{
            width: 100% !important;
        }
        ::v-deep .el-table .el-table__body{
            width: 100% !important;
        }
        .paginationContent{
            text-align: right;
            margin-top: 10px;
            ::v-deep .el-pagination{
                padding-right: 0 !important;
            }
        }

    }
</style>