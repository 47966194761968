import axios from "../axios";
/***
 * 通知书
 */

/***
 * 列表
 * @param params
 */
export const winNoticeList = params => {
    return axios({
        url:  `/api/mcn/common/winningNotice/list`,
        method: "get",
        params
    });
};
/***
 * 编辑、保存中标通知书
 * @param params
 */
export const addOrUpdateNotice = data => {
    return axios({
        url:  `/api/mcn/common/winningNotice/addOrUpdate`,
        method: "post",
        data
    });
};
/***
 * 新增通知书时查询 中标单位相关信息
 * @param params
 */
export const addInfoBaseNotice = params => {
    return axios({
        url:  `/api/mcn/common/winningNotice/addInfo`,
        method: "get",
        params
    });
};
/***
 * 编辑获取详情
 * @param params
 */
export const baseNotice = noticeId => {
    return axios({
        url:  `/api/mcn/common/winningNotice/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 提交审核
 * @param params
 */
export const commonSubmitAudit = data => {
    return axios({
        url:  `/api/mcn/winning_common/submit_audit`,
        method: "post",
        data
    });
};

/***
 * 发布
 * @param params
 */
export const commonIssue = data => {
    return axios({
        url:  `/api/mcn/winning_common/issue`,
        method: "post",
        data
    });
};

/***
 * 驳回
 * @param params
 */
export const commonReject = data => {
    return axios({
        url:  `/api/mcn/winning_common/reject`,
        method: "post",
        data
    });
};
/***
 * 撤回提交审核
 * @param params
 */
export const commonResetSubmit = data => {
    return axios({
        url:  `/api/mcn/winning_common/reset_submit`,
        method: "post",
        data
    });
};

/***
 * 撤回代理提交审核
 * @param params
 */
export const commonResetAgentSubmit = data => {
    return axios({
        url:  `/api/mcn/winning_common/reset_agent_submit`,
        method: "post",
        data
    });
};
/***
 * 删除
 * @param params
 */
export const commonDelete = noticeId => {
    return axios({
        url:  `/api/mcn/common/winningNotice/delete/${noticeId}`,
        method: "post"
    });
};

/***
 * 修改 -上传变更证明附件
 * @param params
 */
export const modifyUploadChangeFile = data => {
    return axios({
        url:  `/api/mcn/winning_common/modify`,
        method: "post",
        data
    });
};


/***
 * 通知公告管理-通知列表
 * @param params
 */
export const noticeInfoList = params => {
    return axios({
        url:  `/api/notice/list`,
        method: "get",
        params
    });
};

/***
 * 通知公告管理-通知列表
 * @param params
 */
export const deleteProject = noticeId => {
    return axios({
        url:  `/api/notice/delete/${noticeId}`,
        method: "post"
    });
};

/***
 * 通知公告管理-通知列表
 * @param params
 */
export const submitNoticeInfo = data => {
    return axios({
        url:  `/api/notice/edit`,
        method: "post",
        data
    });
};

/***
 * 通知公告管理-首页查看详细
 * @param params
 */
export const noticeDetailsHome = noticeId => {
    return axios({
        url:  `/api/notice/details/head/${noticeId}`,
        method: "get"
    });
};

/***
 * 通知公告管理-通知公告详情
 * @param params
 */
export const noticeDetails = noticeId => {
    return axios({
        url:  `/api/notice/details/${noticeId}`,
        method: "get"
    });
};


/***
 * 通知公告管理-启用-禁用
 * @param params
 */
export const noticeIsEnable = data => {
    return axios({
        url:  `/api/notice/isEnable`,
        method: "post",
        data
    });
};

