import axios from "../axios";

/**
 * 投标信息查看
 */
/**
 * 投标信息列表
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getTenderList = params => {
    return axios({
        url: "/api/bidsection/tender_view/list",
        method: "get",
        params
    });
};
/**
 *  获取缴费明细
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const getTenderPayDetails = params => {
    return axios({
        url: "/api/bidsection/tender_view/payment/sub_account/list",
        method: "get",
        params
    });
};

/**
 *  手动获取保单保函
 * @param params
 * @returns {*|Promise|Promise<any>}
 */
export const remoteQueryinsurance = data => {
    return axios({
        url: "/api/insurance/remoteQuery",
        method: "post",
        params:data
    });
};
