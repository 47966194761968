import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import loginStore from "./modules/login-store";
import projectStore from "./modules/project-store";
import menuStore from "./modules/menu-store";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        loginStore,
        projectStore,
        menuStore
    }
});
// 处理刷新后store丢失
export function setupStore() {
    store.dispatch("loginStore/loadStore");
    store.dispatch("projectStore/loadStore");
    store.dispatch("menuStore/loadStore");
}

export default store;
