import axios from "../axios";
/***
 * 候选人公示
 */


/***
 * 中标候选人公示列表
 * @param params
 */
export const candidateNoticeList = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/list`,
        method: "get",
        params
    });
};
/***
 * 新增、更正按钮显示
 * @param params
 */
export const buttonShow = bidSectionId => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/buttonShow/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 中标候选人公示 -- 删除
 * @param params
 */
export const candidateDelete = noticeId => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/delete/${noticeId}`,
        method: "post"
    });
};
/***
 * 中标候选人公示 -- 撤回
 * @param params
 */
export const candidateRevoke = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/list`,
        method: "get",
        params
    });
};
/***
 * 中标候选人公示 -- 发布
 * @param params
 */
export const candidatePublic = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/list`,
        method: "get",
        params
    });
};
/***
 * 中标候选人公示 -- 提交
 * @param params
 */
export const candidateSubmit = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/list`,
        method: "get",
        params
    });
};

/***
 * 中标候选人公示 -- 保存或编辑候选人公示
 * @param params
 */
export const candidateNoticeAdd = data => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/add`,
        method: "post",
        data
    });
};
/***
 * 中标候选人公示 -- 新增时获取基本信息
 * @param params
 */
export const candidateNoticeInfo = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/base/info`,
        method: "get",
        params
    });
};
/***
 * 中标候选人公示 -- 候选人更正基本信息
 * @param params
 */
export const correctBaseInfo = bidSectionId => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/correct/baseinfo/${bidSectionId}`,
        method: "get"
    });
};
/***
 * 中标候选人公示 -- 校验标段相关信息(新增获取基本信息，保存草稿，保存提交之前需要调用)
 * @param params
 */
export const candidateNoticeCheck = params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/check`,
        method: "get",
        params
    });
};
/***
 * 中标候选人公示 -- 查询报名招标供应商及报价信息（候选人公示）新增第*名时查询
 * @param params
 */
export const candidateCompanies= params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/candidateCompanies/search`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 查看全库供应商
 * @param params
 */
export const candidateAllSearch= params => {
    return axios({
        url:  `/api/mcn/result/common/candidate/allSearch`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 查看全库供应商  库内直接比价
 * @param params
 */
export const candidateAllSearchKNZJ= params => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/candidate/allSearch`,
        method: "get",
        params
    });
};
/***
 * 新增候选供应商 获取详情
 * @param params
 */
export const candidateEditBaseInfo= noticeId => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/baseinfo/${noticeId}`,
        method: "get"
    });
};
/***
 * 新增候选供应商 查看全库供应商
 * @param params
 */
export const winningCandidateBidders= noticeId => {
    return axios({
        url:  `/api/mcn/common/candidateNotice/winningCandidateBidders/${noticeId}`,
        method: "get"
    });
};