<!--
 确认弹框组件
 @type：component
 @依赖： {Dialog,Button} from 'element-ui'
 @Arthur 丁街强
 -->

<template>
    <el-dialog v-if="visible" :append-to-body="true"  :visible.sync="visible" width=" 440px" height="337px" class="dialogBox" :show-close="false">
        <div class="dialogBoxCon">
            <img v-if="type==1" src="../../assets/images/layout/dialog-tip.png"/>
            <img v-else-if="type==2" src="../../assets/images/layout/dialog-success.png"/>
            <img v-else-if="type==3" src="../../assets/images/layout/dialog-fail.png"/>
            <div class="text" v-html="content"></div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="NoHandleClick()">{{ btnNo }}</el-button>
            <el-button size="small" type="primary" @click="comfirmHandleClick()">{{ btnYes }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { Dialog, Button } from "element-ui";
export default {
    name: "comfirmDialog",
    components: {
        elDialog: Dialog,
        elButton: Button
    },
    data() {
        return {
            type:1,// 类型  1 提示  2 成功  3 失败
            visible: false,
            title: "确认",
            btnYes: "确 定",
            btnNo: "取 消",
            content: "是否要执行此操作？",
            callback() {},
            noCallBack() {}
        };
    },
    methods: {
        /*
         * 显示确认弹窗
         * */
        comfirmHandleClick() {
            this.visible = false;

            let cb = this.callback;
            if (cb !== null && cb !== undefined) {
                if (typeof cb !== "function") {
                    throw new Error("callback必须是一个function");
                } else {
                    cb();
                }
            }
        },
        NoHandleClick() {
            this.visible = false;

            let cb = this.noCallBack;
            if (cb !== null && cb !== undefined) {
                if (typeof cb !== "function") {
                    throw new Error("noCallBack必须是一个function");
                } else {
                    cb();
                }
            }
        }
    }
};
</script>
<style>
.el-dialog__header {
    background-color: #fff !important;
}
</style>
<style lang="scss" scoped>
.dialogBox {
    left: 0;
    top: 0;
    ::v-deep{
        .el-dialog{
            border-radius: 4px;
        }
        .el-dialog__header{
            border-radius: 4px;
        }
    }
    .dialogBoxCon {
        @include display-flex();
        @include flex-flow(column);
        @include align-items(center);

        .el-icon-warning-outline {
            font-size: 42px;
            color: red;
            margin-bottom: 1rem;
            position: relative;
            top: -1px;
        }
        .text {
            margin-top: 22px;
            max-width: 82%;
            font-size: 18px;
            color: #000000;
            line-height: 24px;
        }
    }
    .dialog-footer {
        display: block;
        padding-bottom: 20px;
        text-align: center;
        button {
            display: inline-block;
            margin-left: 22px;
        }
    }
}
</style>
