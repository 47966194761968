// 编制文件
import axios from "../axios";

/***
 * 编制采购文件列表
 * @param params
 */
export const purchaseFileList = params => {
    return axios({
        url: `/api/purchase/file/list`,
        method: "get",
        params
    });
};


/***
 * 删除-编制采购文件
 * @param data
 */
export const purchaseFileDelete = data => {
    return axios({
        url: "/api/purchase/file/delete?id="+data.id,
        method: "post",
        data
    });
};

/***
 * 编制采购招标文件详情
 * @param params
 */
export const getPurchaseFileDetails = params => {
    return axios({
        url: `/api/purchase/document/details`,
        method: "get",
        params
    });
};

/***
 * 项目信息列表
 * @param params
 */
export const purchaseProjectList = params => {
    return axios({
        url: `/api/purchase/project/message`,
        method: "get",
        params
    });
};
/***
 * 项目信息回显
 * @param params
 */
export const purchaseProjectBasicMessage = params => {
    return axios({
        url: `/api/purchase/project/basic/message`,
        method: "get",
        params
    });
};

/***
 * 编制文件新增
 * @param data
 */
export const purchaseReviewMethodAdd = data => {
    return axios({
        url: "/api/purchase/reviewMethod/add",
        method: "post",
        data
    });
};


/***
 * 编制采购文件投标详情回显
 * @param params
 */
export const purchaseBiddingDetails = params => {
    return axios({
        url: `/api/purchase/bidding/details`,
        method: "get",
        params
    });
};


/***
 * 编制文件新增
 * @param data
 */
export const purchaseReviewMethodBidAdd = data => {
    return axios({
        url: `/api/purchase/reviewMethodBid/add`,
        method: "post",
        data
    });
};


/***
 * 操作记录
 * @param params
 */
export const purchaseOperatRecord = params => {
    return axios({
        url: `/api/purchase/operat/record`,
        method: "get",
        params
    });
};

/***
 * 投标文件撤回
 * @param data
 */
export const purchaseOperatDeal = data => {
    return axios({
        url: `/api/purchase/operat/deal?id=${data.id}`,
        method: "post",
        data
    });
};

/***
 * 复制
 * @param params
 */
export const purchaseCopy = data => {
    return axios({
        url: `/api/purchase/copy/num?id=${data.id}&bidSectionId=${data.bidSectionId}&onlineDocName=${data.onlineDocName}`,
        method: "post",
        data
    });
};

/***
 * 获取报价清单
 * @param params
 */
export const getOfferData = params => {
    return axios({
        url: `/api/purchase/quote/list`,
        method: "get",
        params
    });
};

/***
 * 导入报价清单
 * @param params
 */
export const importOfferList = (data, params) => {
    return axios({
        url: `/api/purchase/quotationList/import?bidSectionId=${params.bidSectionId}`,
        method: "post",
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

/***
 * 导出报价清单
 * @param params
 */
export const exportOfferList = data => {
    return axios({
        url: `/api/purchase/export`,
        method: "post",
        data,
        responseType:'blob'
    });
};

/***
 * 供应商递交投标文件-采购文件列表
 * @param params
 */
export const getPurchaseFileList = params => {
    return axios({
        url: `/api/tender/file/list`,
        method: "get",
        params
    });
};

/***
 * 采购文件列表--投标文件撤回
 * @param data
 */
export const recallPurchaseFile = data => {
    return axios({
        url: `/api/purchase/accessBidSectionId/deal?bidSectionId=${data.bidSectionId}`,
        method: "post",
        data
    });
};