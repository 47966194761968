// 敦伦报价
import axios from "../axios";

/***
 * 获取列表
 * @param params
 */
export const getList = params => {
    return axios({
        url:  `/api/round_quotation/purchase/list`,
        method: "get",
        params
    });
};

/***
 * 获取详情
 * @param params
 */
export const getDetails = params => {
    return axios({
        url:  `/api/round_quotation/details`,
        method: "get",
        params
    });
};


/***
 * 获取控制价类型
 * @param params
 */
export const getControlPriceType = params => {
    return axios({
        url:  `/api/common/controlPriceType`,
        method: "get",
        params
    });
};
