<template>
    <div class="SystemLayout">
        <Header  />
        
        <div class="layoutBg">
            <div class="sliderContent">
                <Slider />
            </div>
            <div class="content">
               <!-- <breadcurmbs v-if="showBreadCrumbs"></breadcurmbs>-->
                <div :class="['content-main',showBreadCrumbs?'content-main-height':'content-main-no-breadcurmbs-height']">
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Header from "./components/Header.vue";
import Slider from "./components/Slider.vue";
import Breadcurmbs from "./components/Breadcrumbs"
import { mapState } from "vuex";

export default {
    data() {
        return {
            showBreadCrumbs: true
        };
    },
    components: { Header, Slider, Breadcurmbs  },
    computed: {
        ...mapState("loginStore", ["roleCode"]),
    },
    mounted() {
        this.showBreadCrumbs = this.$route.meta.showBreadCrumbs;
    },
    methods:{
    }
};
</script>

<style lang="scss" scoped>
.SystemLayout {
    width: 100%;
    min-height: 100vh;
    background: #F1F1F1;
    .layoutBg {
        position: relative;
        // padding: 0px 22px 26px 0px;
        width: 100%;

        height: calc(100vh - #{$headerHeight});
        // background: url("../assets/images/layout/bg.png");
        background-size: 100% 100%;
        display: flex;
        .sliderContent {
            width: $slider-width;
           margin-right: 14px;
            padding: 14px 0px 14px 12px;
        }
        .content {
            flex: 1;
            margin: 0px 17px 0px 16px;
            overflow: hidden;
            height: calc(100vh - #{$headerHeight} - 12px);
            .content-main{
                margin: 12px 0px 24px 0px;
                border-radius: 6px;
                overflow: auto;

            }
            .content-main-height{
                height: calc(100vh - #{$headerHeight}  - #{$breadcrumbs-hight});
            }
            .content-main-no-breadcurmbs-height{
                height: calc(100vh - #{$headerHeight}  - 24px );
            }
        }

    }
}
</style>
