import axios from "../axios";

export const getList = params => {
    return axios({
        url: `/api/OnlineInvoice/authorityCompanyList`,
        method: "get",
        params
    });
};
export const delAuthority = params => {
    return axios({
        url: `/api/OnlineInvoice/delAuthority`,
        method: "get",
        params
    });
};

export const getOrg = params => {
    return axios({
        url: `/dept/frontDeptList`,
        method: "get",
        params
    });
};


export const saveOrg = data => {
    return axios({
        url: `/api/OnlineInvoice/authorityCompany`,
        method: "post",
        data
    });
};
